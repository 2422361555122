import styled from "styled-components";

const Container = styled.main`
  min-height: 100vh;
  min-height: 100dvh;
  background-color: var(--black130);

  & img {
    animation: fadeOut 1s 1.5s linear forwards;
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export { Container };

export default { Container };
