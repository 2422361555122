import Styles from "./styles";
import { useNavigate } from "react-router-dom";

// img
import IconPrevBlack from "resources/image/icon/icon_prev.svg";

export default function PrevButton({ white, text, prevEvent }) {
  const navigate = useNavigate();

  return (
    <Styles.Container>
      <button
        type="button"
        onClick={() => {
          prevEvent
            ? prevEvent()
            : navigate(-1)
        }}
      >
        <img src={IconPrevBlack} alt="IconPrevBlack" />
      </button>

      {text && <p>{text}</p>}
    </Styles.Container>
  );
}
