import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  padding: calc(100vw * (91 / 390)) 0 calc(100vw * (50 / 390));
  color: #fff;
  text-align: center;
  background-color: var(--black130);
  overflow: auto;
  z-index: 11;

  & .closeButton {
    position: absolute;
    top: calc(100vw * (15 / 390));
    right: calc(100vw * (29 / 390));
    width: calc(100vw * (25 / 390));
    height: calc(100vw * (25 / 390));
  }

  & .tag {
    width: fit-content;
    margin: 0 auto calc(100vw * (15 / 390));
    padding: calc(100vw * (4 / 390)) calc(100vw * (21 / 390));
    font-weight: 600;
    color: var(--black130);
    background-color: #fff;
    border-radius: calc(100vw * (23 / 390));
  }

  & .title {
    margin-bottom: calc(100vw * (3 / 390));
    font-size: calc(100vw * (32 / 390));
    font-weight: 600;
  }

  & .text {
    margin-bottom: calc(100vw * (40 / 390));
    font-weight: 300;

    & span {
      font-weight: 600;
    }
  }

  & .benefitListWrap {
    display: flex;
    flex-wrap: wrap;
    gap: calc(100vw * (7 / 390)) calc(100vw * (10 / 390));
    margin-bottom: calc(100vw * (59 / 390));

    & .benefitList {
      width: calc(calc(100% - calc(100vw * (10 / 390))) / 2);
      padding: calc(100vw * (7 / 390)) 0;
      font-weight: 300;
      background-color: var(--black120);
      border: calc(100vw * (1 / 390)) solid var(--black70);
      border-radius: calc(100vw * (5 / 390));

      &:first-child {
        width: 100%;
      }
    }
  }

  & .radioListWrap {
    display: flex;
    gap: calc(100vw * (10 / 390));
    margin-bottom: calc(100vw * (92 / 390));

    & .radioList {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100vw * (166 / 390));
      height: calc(100vw * (182 / 390));
      padding: calc(100vw * (28 / 390)) 0 calc(100vw * (20 / 390));
      border: 1px solid var(--black80);
      border-radius: calc(100vw * (15 / 390));

      &:has(input:checked) {
        padding: calc(100vw * (26 / 390)) 0 calc(100vw * (18 / 390));
        border: calc(100vw * (3 / 390)) solid #fff;
        background-color: rgba(255, 255, 255, 0.2);
      }

      & input {
        display: none;

        &:checked {
          & ~ .radioTag {
            font-weight: 600;
            color: var(--black130);
            background-color: #fff;
            border-color: #fff;
          }

          & ~ .checkbox::after {
            position: absolute;
            top: 50%;
            height: 50%;
            transform: translate(-50%, -50%);
            width: calc(100vw * (8 / 390));
            height: calc(100vw * (8 / 390));
            background-color: #fff;
            border-radius: 50%;
            content: "";
          }

          & ~ .beforePrice {
            color: #fff;
          }
          & ~ .beforePrice_en {
            color: #fff;
          }
          & ~ .through_en {
            color: #BDBDBD;
          }
          & ~ .price {
            color: #fff;
          }
        }
      }

      & label {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      & .radioTag {
        position: absolute;
        top: calc(100vw * (-14 / 390));
        left: 50%;
        transform: translateX(-50%);
        width: max-content;
        padding: calc(100vw * (5 / 390)) calc(100vw * (17 / 390));
        font-size: calc(100vw * (12 / 390));
        font-weight: 300;
        border-radius: calc(100vw * (15 / 390));
        border: 1px solid var(--black80);
        background-color: var(--black120);
      }

      & .checkbox {
        position: relative;
        width: calc(100vw * (12 / 390));
        height: calc(100vw * (12 / 390));
        margin-bottom: calc(100vw * (5 / 390));
        border: calc(100vw * (1 / 390)) solid #fff;
        border-radius: 50%;
      }

      & .period {
        font-size: calc(100vw * (25 / 390));
        font-weight: 600;
      }

      & .beforePrice {
        font-weight: 300;
        color: #959595;
      }
      & .through_en{
        font-size: calc(100vw * (14 / 390));
        font-family: "Roboto";
        text-decoration: line-through;
        font-weight: 600;
        color: #6D6D6D;
      }
      & .beforePrice_en{
        font-size: calc(100vw * (16 / 390));
        font-family: "Roboto";
        font-weight: 600;
        color: #959595;
      }

      & .price {
        margin-top: auto;
        font-size: calc(100vw * (20 / 390));
        font-weight: 600;
        color: var(--black60);
      }
    }
  }

  & .bottomText {
    margin-bottom: calc(100vw * (15 / 390));
    font-weight: 300;
  }
`;

export { Container };

export default { Container };
