import Styles from "./styles";
import PieChart from "../PieChart";
import LineChart from "../LineChart";
import BarChart from "../BarChart";
import { etc } from "utils";
import { useEffect, useState } from "react";
import { useLanguage, useSummary } from "hooks";
import { dateCalculator, getDateType } from "utils/date";

// img
import IconArrowLeft from "resources/image/icon/icon_arrow_left_black.svg";
import IconArrowRight from "resources/image/icon/icon_arrow_right_black.svg";
import IconArrowTop from "resources/image/icon/icon_arrow_top_mini.svg";
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom_mini.svg";
import IconNoChange from "resources/image/icon/icon_no_change.svg";

function Statistics() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix == "";

  const [tabIndex, setTabIndex] = useState(0);
  const [pivotDate, setPivotDate] = useState(0);

  const tabList = [
    { type: "today", text: "TODAY" },
    { type: "weekly", text: "WEEKLY" },
    { type: "monthly", text: "MONTHLY" },
  ];

  const monthName = (month) => {
    if (month == 1) return "January";
    else if (month == 2) return "February";
    else if (month == 3) return "March";
    else if (month == 4) return "April";
    else if (month == 5) return "May";
    else if (month == 6) return "June";
    else if (month == 7) return "July";
    else if (month == 8) return "August";
    else if (month == 9) return "September";
    else if (month == 10) return "October";
    else if (month == 11) return "November";
    else if (month == 12) return "December";
  };

  const dateTypeChange = (date = "", dayCut, weekly) => {
    return languageQuarter
      ? `${date.split("-")[0]}년 ${parseInt(date?.split("-")[1])}월
        ${dayCut ? "" : parseInt(date.split("-")[2]) + "일"}`
      : `${weekly
        ? monthName(parseInt(date?.split("-")[1])).slice(0, 3)
        : monthName(parseInt(date?.split("-")[1]))
      } ${dayCut ? "" : parseInt(date.split("-")[2]) + ","} ${date.split("-")[0]}`;
  };

  const todayRange = getDateType(dateCalculator(new Date(), pivotDate), "-");
  const weaklyRange = new Date(dateCalculator(new Date(), pivotDate * 7));
  const monthlyRange = new Date(dateCalculator(new Date(), pivotDate, "mo"));

  const mondayDate = getDateType(
    dateCalculator(new Date(weaklyRange), -weaklyRange.getDay()),
    "-"
  );
  const sundayDate = getDateType(dateCalculator(new Date(mondayDate), 6), "-");
  const monthFirstDate = `${getDateType(monthlyRange, "-").substring(
    7,
    -1
  )}-01`;
  const monthLastDate = getDateType(
    dateCalculator(dateCalculator(monthFirstDate, 1, "mo"), -1),
    "-"
  );

  const startedDate = (type) => ({
    today: todayRange,
    weekly: mondayDate,
    monthly: monthFirstDate,
  }[type]);
  const endedDate = (type) => ({
    today: todayRange,
    weekly: sundayDate,
    monthly: monthLastDate,
  }[type]);

  const type = tabList[tabIndex].type;
  const summaryInfo = useSummary({
    summary: {
      started_at: startedDate(type) + " 00:00:00",
      order: tabIndex + 1,
      ended_at: endedDate(type) + " 23:59:59",
    },
  });

  const summaryData = summaryInfo.update?.summary?.data || [];
  const lineData = {
    weight: summaryData.weight?.list.map((item) => parseInt(item.weight)) || 0,
  };
  const barData = {
    eat: summaryData.food?.list.map((item) => parseInt(item.eat)) || 0,
    consume: summaryData.food?.list.map((item) => parseInt(item.consume)) || 0,
    totalCalories: parseInt(summaryData.food?.avg_eat) || 0,
    totalWork: parseInt(summaryData.food?.avg_consume) || 0,
  };
  const pieData = {
    carbohydrate: parseInt(summaryData.food?.avg_carbohydrate) || 0,
    protein: parseInt(summaryData.food?.avg_protein) || 0,
    fat: parseInt(summaryData.food?.avg_fat) || 0,
    total: parseInt(summaryData.food?.avg_consume) || 0,
  };

  const data = (type) => {
    switch (type) {
      case "today":
        return dateTypeChange(todayRange);
      case "weekly":
        return `${dateTypeChange(getDateType(mondayDate, "-"), false, true
        )} - ${dateTypeChange(getDateType(sundayDate, "-"), false, true)}`;
      case "monthly":
        return dateTypeChange(monthFirstDate, true);
    }
  };

  const renderIcon = (change) => {
    switch (change) {
      case "감소": return IconArrowBottom;
      case "동일": case null: return IconNoChange;
      case "증가": return IconArrowTop;
    }
  };

  const typeData = ["감소", "동일", "증가"]
  const typeDataEN = ["Decrease", "Same", "Increase"];

  const bodyChangeList = [
    {
      title: languageQuarter ? "눈바디 변화" : "Body size change",
      detail: <b>{(
        languageQuarter
          ? summaryData.weight?.state
          : typeDataEN[typeData.indexOf(summaryData.weight?.state)]
        ) || "--"
      }</b>,
    },
    {
      title: languageQuarter ? "체중" : "Weight",
      detail: (
        <>
          <div className="weight">
            <b>{parseInt(summaryData.weight?.weight) || "--"}</b>kg
          </div>
          <div className="weightChange">
            {summaryData.weight?.last_weight == 0.0
              ? 0
              : Math.abs((summaryData.weight?.weight || 0) - (summaryData.weight?.last_weight || 0))}

            <div className="icon">
              <img
                src={renderIcon(
                  (summaryData.weight?.weight || 0) == (summaryData.weight?.last_weight || 0) ? "동일" : 
                  (summaryData.weight?.weight || 0) - (summaryData.weight?.last_weight || 0) > 0 ? "증가" : "감소"
                )}
                alt="changIcon"
              />
            </div>
          </div>
        </>
      ),
    },
  ];

  const totalList = [
    {
      title: languageQuarter ? "총 소모 칼로리" : "Calories burned",
      detail: (
        <>
          <b>{parseInt(summaryData.food?.avg_eat) || "--"}</b>kcal
        </>
      ),
    },
    {
      title: languageQuarter ? "총 운동시간" : "Workout time",
      detail: (
        <>
          {etc.changeTime(summaryData.work?.total_work_time, languageQuarter)}
        </>
      ),
    },
  ];

  const renderGraphs = (type) => {
    switch (type) {
      case "today":
        return (
          <>
            <div className="bodyChangeWrap">
              {bodyChangeList?.map((item, index) => {
                return (
                  <div className="bodyChange" key={"bodyChange" + index}>
                    <p className="title">{item.title}</p>
                    <div className="detail">{item.detail}</div>
                  </div>
                );
              })}
            </div>
            <PieChart data={pieData} />
          </>
        );
      case "weekly":
        return (
          <>
            <LineChart data={lineData} summaryData={summaryData} type={type} />
            <BarChart data={barData} summaryData={summaryData} type={type} />
            <PieChart data={pieData} />
          </>
        );
      case "monthly":
        return (
          <>
            <LineChart data={lineData} summaryData={summaryData} type={type} />
            <BarChart data={barData} summaryData={summaryData} type={type} />
            <PieChart data={pieData} />
          </>
        );
    }
  };

  useEffect(() => {
    setPivotDate(0);
  }, [type]);

  return (
    <Styles.Container>
      <div className="inner">
        <ul className="periodTab">
          {tabList.map((item, index) => {
            return (
              <li
                className={
                  "periodTabList roboto" + (tabIndex === index ? " on" : "")
                }
                key={"periodTabList" + index}
                onClick={() => {
                  setTabIndex(index);
                }}
              >
                <span>{item.text}</span>
              </li>
            );
          })}
        </ul>

        <div className="dateWrap">
          <button type="button" onClick={() => setPivotDate((p) => p - 1)}>
            <img src={IconArrowLeft} alt="IconArrowLeft" />
          </button>

          {data(type)}

          <button type="button" onClick={() => setPivotDate((p) => p + 1)}>
            <img src={IconArrowRight} alt="IconArrowRight" />
          </button>
        </div>

        {renderGraphs(type)}

        <ul className="totalWrap">
          {totalList?.map((item, index) => {
            return (
              <li className="total" key={"total" + index}>
                <p className="title">{item.title}</p>

                <p className="detail">
                  <span>{languageQuarter ? "약" : "Total"}</span> {item.detail}
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    </Styles.Container>
  );
}

export default Statistics;
