import styled from "styled-components";

const Container = styled.div`
  & label {
    display: block;
    margin-bottom: calc(100vw * (10 / 390));
    font-size: calc(100vw * (20 / 390));
    font-weight: 600;
  }

  & .inputWrap {
    position: relative;

    &:has(button) input {
      padding-right: calc(100vw * (45 / 390));
    }

    & button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: calc(100vw * (10 / 390));
      width: calc(100vw * (25 / 390));
      height: calc(100vw * (25 / 390));
    }
  }

  & input {
    width: 100%;
    height: calc(100vw * (40 / 390));
    padding: 0 calc(100vw * (10 / 390));
    font-size: calc(100vw * (16 / 390));
    font-weight: 300;
    border-bottom: 1px solid var(--black130);

    &::placeholder {
      color: var(--black50);
    }
  }
`;

export { Container };

export default {
  Container,
};
