import styled from "styled-components";

// img
import IconArrowRight from "resources/image/icon/icon_arrow_right_white.svg";

const Container = styled.main`
  position: relative;

  & .top {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vw * (67 / 390));
    font-size: calc(100vw * (20 / 390));
    font-weight: 600;

    & .completeButton {
      position: absolute;
      top: calc(100vw * (19 / 390));
      right: calc(100vw * (24 / 390));
      font-size: calc(100vw * (20 / 390));
      font-weight: 600;
    }
  }

  & .dateSelectContainer {
    position: absolute;
    top: calc(100vw * (67 / 390));
    left: 50%;
    transform: translateX(-50%);
    padding: calc(100vw * (15 / 390)) calc(100vw * (20 / 390))
      calc(100vw * (25 / 390));
    width: calc(100vw * (290 / 390));
    background-color: #fff;
    border: calc(100vw * (1 / 390)) solid var(--black130);
    border-radius: calc(100vw * (5 / 390));
    z-index: 5;

    & .yearSelectWrap {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: calc(100vw * (20 / 390));
      margin-bottom: calc(100vw * (20 / 390));
      font-weight: 600;

      & button {
        display: block;
        width: calc(100vw * (15 / 390));
        height: calc(100vw * (15 / 390));
      }
    }

    & .yearListWrap {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: calc(100vw * (14 / 390));

      & .yearList {
        & button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc(100vw * (73 / 390));
          height: calc(100vw * (52 / 390));
          font-weight: 600;
          color: var(--black130);
          background-color: #fff;
          border: calc(100vw * (1 / 390)) solid var(--black130);
          border-radius: calc(100vw * (5 / 390));

          &:disabled {
            font-weight: 300;
            color: var(--black50);
            border-color: var(--black50);
          }
        }
      }
    }
  }

  & .photoContainer {
    height: calc(100vh - calc(100vw * (261 / 390)));
    height: calc(100dvh - calc(100vw * (261 / 390)));
    overflow: auto;

    & .photoListWrap {
      display: flex;
      flex-wrap: wrap;

      & .photoList {
        position: relative;
        width: calc(100vw * (130 / 390));
        height: calc(100vw * (130 / 390));

        & input {
          display: none;

          &:checked + label {
            background-color: rgba(255, 255, 255, 0.3);
            border: calc(100vw * (3 / 390)) solid #fff;
          }
        }

        & label {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          z-index: 1;

          &.before::after,
          &.after::after {
            position: absolute;
            bottom: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: calc(100vw * (25 / 390));
            padding: 0 calc(100vw * (15 / 390));
            font-size: calc(100vw * (12 / 390));
            font-weight: 600;
            background-color: #fff;
          }

          &.before::after {
            content: "BEFORE";
          }

          &.after::after {
            content: "AFTER";
          }
        }
      }
    }
  }

  & .bottom {
    display: flex;
    align-items: flex-end;
    height: calc(100vw * (158 / 390));
    padding-bottom: calc(100vw * (18 / 390));
    color: #fff;
    background-color: var(--black130);

    & .inner {
      display: flex;
      justify-content: space-between;
    }

    & .dateList {
      position: relative;
      text-align: center;

      &:first-child::after {
        position: absolute;
        top: calc(100vw * (49 / 390));
        left: calc(100% + calc(100vw * (15 / 390)));
        transform: translateY(-50%);
        width: calc(100vw * (15 / 390));
        height: calc(100vw * (15 / 390));
        background: url(${IconArrowRight}) no-repeat center / cover;
        content: "";
      }

      & .title {
        margin-bottom: calc(100vw * (14 / 390));
        font-weight: 600;
      }

      & .dateWrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: calc(100vw * (150 / 390));
        height: calc(100vw * (74 / 390));
        border: calc(100vw * (1 / 390)) solid #fff;
        border-radius: calc(100vw * (5 / 390));

        & .date {
          margin-bottom: calc(100vw * (10 / 390));
          font-weight: 300;
        }

        & .weight {
          font-weight: 600;
        }
      }
    }
  }

  & .adArea {
    height: calc(100vw * (36 / 390));
    background-color: var(--black130);
  }
`;

export { Container };

export default { Container };
