import { useQuery } from "react-query";
import { api } from "service";

export default function useProduct() {

  const getProduct = useQuery(["product"], () => {
    return api.product.getProduct();
  }, {
    refetchOnWindowFocus: false,
    enabled: true
  });

  return {
    getProduct
  };
}
