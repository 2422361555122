import Styles from "./styles";

export default function BasicButton({
  buttonText,
  colorType,
  sizeType,
  onClick,
  disabled,
}) {
  return (
    <Styles.Button
      onClick={onClick}
      type="button"
      sizeType={sizeType}
      colorType={colorType}
      disabled={disabled}
    >
      {buttonText}
    </Styles.Button>
  );
}
