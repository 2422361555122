import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  top: calc(100vw * (20 / 390));
  left: calc(100vw * (24 / 390));
  display: flex;
  align-items: center;
  gap: calc(100vw * (10 / 390));
  font-size: calc(100vw * (20 / 390));
  font-weight: 600;
  z-index: 8;

  & button {
    width: calc(100vw * (40 / 390));
    height: calc(100vw * (25 / 390));
  }
`;

export { Container };

export default {
  Container,
};
