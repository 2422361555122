import styled from "styled-components";

const Container = styled.div`
  position: relative;
  aspect-ratio: 1;
  color: #fff;

  & .stamfit {
    position: absolute;
    top: calc(100vw * (8 / 390));
    right: calc(100vw * (10 / 390));
    font-size: calc(100vw * (6 / 390));
    font-weight: 900;
  }

  & .dateWrap {
    position: absolute;
    bottom: calc(100vw * (7 / 390));
    left: calc(100vw * (10 / 390));

    & .date {
      font-size: calc(100vw * (7.5 / 390));
      font-weight: 300;
    }

    & .time {
      font-size: calc(100vw * (10 / 390));
      font-weight: 900;
    }
  }

  & .img {
    height: 100%;
  }
  & .img_detail {
    height: auto;
  }

  & .weight,
  .kcal {
    position: absolute;
    bottom: calc(100vw * (10 / 390));
    right: calc(100vw * (10 / 390));
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vw * (15 / 390));
    padding: 0 calc(100vw * (8 / 390));
    background-color: rgba(9, 9, 9, 0.6);
    border: calc(100vw * (1 / 390)) solid #fff;
    border-radius: calc(100vw * (5 / 390));
    font-size: calc(100vw * (10 / 390));
    font-weight: 900;

    & span {
      margin: calc(100vw * (2 / 390)) 0 0 calc(100vw * (2 / 390));
      font-size: calc(100vw * (6 / 390));
      font-weight: normal;
    }
  }

  &.tall {
    aspect-ratio: 390 / 428;
  }

  &.big {
    & .stamfit {
      top: calc(100vw * (20 / 390));
      right: calc(100vw * (25 / 390));
      font-size: calc(100vw * (14 / 390));
    }

    & .dateWrap {
      bottom: calc(100vw * (18 / 390));
      left: calc(100vw * (25 / 390));

      & .date {
        margin-bottom: calc(100vw * (1 / 390));
        font-size: calc(100vw * (16 / 390));
        font-weight: normal;
      }

      & .time {
        font-size: calc(100vw * (22 / 390));
      }
    }

    & .weight,
    .kcal {
      bottom: calc(100vw * (25 / 390));
      right: calc(100vw * (25 / 390));
      height: calc(100vw * (36 / 390));
      padding: 0 calc(100vw * (16 / 390));
      border: calc(100vw * (1 / 390)) solid #fff;
      border-radius: calc(100vw * (15 / 390));
      font-size: calc(100vw * (22 / 390));

      & span {
        margin: 0 0 0 calc(100vw * (6 / 390));
        font-size: calc(100vw * (14 / 390));
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
