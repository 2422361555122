import utils from "utils";
import Styles from "./styles";
import { Link, useNavigate } from "react-router-dom";
import { useAuth, useLanguage } from "hooks";

// img
import Logo from "resources/image/logo.svg";
import IconKakao from "resources/image/icon/icon_kakao.svg";
import IconGoogle from "resources/image/icon/icon_google.svg";
import IconApple from "resources/image/icon/icon_apple.svg";
import { AdContainer } from "components/layout";

function LoginPage() {
  const authInfo = useAuth(true);
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix == "";

  const snsLoginList = [
    {
      icon: IconKakao,
      text: languageQuarter ? "카카오 로그인" : "Continue with Kakao",
      onclick: async () => {
        let result = await authInfo.socialLogin("KAKAO");
        // result.success && (window.location.href = result.data);
        utils.etc.callNative("KAKAOLOGIN", result.data);
      },
    },
    {
      icon: IconGoogle,
      text: languageQuarter ? "구글 로그인" : "Continue with Google",
      onclick: async () => {
        let result = await authInfo.socialLogin("GOOGLE");
        utils.etc.callNative("GOOGLELOGIN", result.data);
      },
    },
    {
      icon: IconApple,
      text: languageQuarter ? "애플 로그인" : "Continue with Apple",
      onclick: async () => {
        let result = await authInfo.socialLogin("APPLE");
        result.success && (window.location.href = result.data);
      },
    },
  ];

  return (
    <Styles.Container>
      <div className="inner">
        <div className="logo">
          <img src={Logo} alt="Logo" />
        </div>

        <p className="roboto">STAMP IT, STAMFIT</p>

        <ul>
          {snsLoginList.map((item, index) => {
            return (
              <li key={"list" + index}>
                <button type="button" onClick={item.onclick}>
                  <span className="icon">
                    <img src={item.icon} alt="buttonIcon" />
                  </span>
                  <span className="iconText">{item.text}</span>
                </button>
              </li>
            );
          })}
        </ul>

        <Link to={"/ask"} className="askLink">
          {languageQuarter
            ? "로그인/회원가입에 문제가 있나요?"
            : "Having trouble logging in/signing up?"
          }
        </Link>
      </div>
    </Styles.Container>
  );
}

export default LoginPage;
