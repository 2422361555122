import Styles from "./styles";
import ui from "components/ui";
import { useAuth, useLanguage } from "hooks";

// img
import IconDelete from "resources/image/icon/icon_delete.svg";
import utils from "utils";

function FriendModal({ modalClose }) {
  const authInfo = useAuth();
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix == "";

  const moveToPay = () => {
    utils.etc.callNative("PAY", "PayPage", { id: 3 });
    authInfo.updateUserInfo({
      friend_check_yn: "P",
    });
    modalClose();
  };

  const close = () => {
    authInfo.updateUserInfo({
      friend_check_yn: "M",
    });
    modalClose();
  };

  return (
    <Styles.Container>
      <div className="modalInner">
        <button type="button" className="closeButton" onClick={close}>
          <img src={IconDelete} alt="IconDelete" />
        </button>

        <div className="top">
          <p className="title">
            {languageQuarter ? "무료체험 시작하기" : "Start Free Trial"}</p>
          {languageQuarter
            ? <p className="text">
              친구가 들어왔어요! <br />
              지금부터 무료 체험이 시작됩니다</p>
            : <p className="text">
              Your friend just signed up! <br />
              The free trial starts now.</p>
          }
        </div>

        <div className="bottom">
          <div className="startButton">
            <ui.button.BasicButton
              buttonText={languageQuarter ? "0원으로 시작하기" : "Start for Free"}
              colorType={"white"}
              sizeType={"height50"}
              onClick={moveToPay}
            />
          </div>

          <p className="guideText">
            {languageQuarter
              ? "1개월 무료 체험, 언제든지 취소할 수 있어요"
              : "You can cancel 1-month free trial at anytime."
            }
          </p>
        </div>
      </div>

      <div className="overlay"></div>
    </Styles.Container>
  );
}

export { FriendModal };

export default FriendModal;
