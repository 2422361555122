import Styles from "./styles";
import { IMAGEPATH } from "service/connector";
import { common, ui } from "components";
import { FeedDetailList } from "./components";
import { useBoard, useInfiniteScroll, useLanguage } from "hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import utils from "utils";

function FeedDetailPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const scrollDemoRef = useRef(null);
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix == "";
  
  const [headerImage, setHeaderImage] = useState("");
  const [tabIndex, setTabIndex] = useState(location.state?.category || 0);
  
  const boardInfo = useBoard({
    boardDetailList: {
      category_info_id: tabIndex || "",
      page: 1,
      recordSize: 5,
      id : utils.etc.getSearchParam().id || 0
    },
  });

  const scrollInfo = useInfiniteScroll({
    query : boardInfo.getDetailBoardList,
  });

  const data = boardInfo.getDetailBoardList?.data || [];


  const tabList = languageQuarter
    ? [
      { text: "전체" },
      { text: "눈바디" },
      { text: "식사" },
      { text: "운동" },
      { text: "기타" },
    ] : [
      { text: "All" },
      { text: "Body" },
      { text: "Meal" },
      { text: "Workout" },
      { text: "Etc" },
    ];

  const handleScroll = (e) => {
    if (scrollDemoRef.current) {
      const { scrollTop, scrollLeft } = scrollDemoRef.current;
      let height = 0;
      let childList = e.target.getElementsByClassName("feedDetailList");
      for (let i = 0; i < childList.length; i++) {
        height += e.target.getElementsByClassName("feedDetailList")[i].offsetHeight;
        if (height - 1 >= scrollTop) {
          let thumbnail = data?.pages?.map(v=> v == null ? [] : v.list || [])?.flat()[i]?.thumbnail;
          if(thumbnail)
          setHeaderImage(thumbnail);
          break;
        }
      }
    }
  };
  
  // 첫 로드 흰색 배경 제거
  useEffect(() => {
    setHeaderImage(data?.pages?.map(v=> v == null ? [] : v.list || [])?.flat()[0]?.thumbnail);
  }, [data?.pages?.map(v=> v == null ? [] : v.list || [])?.flat()[0]?.thumbnail]);

  return (
    <Styles.Container>
      <header>
        <ui.button.PrevButton prevEvent={() => navigate("/")} />

        <ul className="tab inner">
          {tabList.map((item, index) => {
            return (
              <li
                className={"tabList" + (tabIndex === index ? " on" : "")}
                key={"tabList" + index}
                onClick={() => {
                  navigate("/feed")
                  setTabIndex(index);
                }}
              >
                <span>{item.text}</span>
              </li>
            );
          })}
        </ul>
        
        <div className="bg">
          <img src={IMAGEPATH + headerImage} alt="" />
        </div>
      </header>

      <main onScroll={handleScroll} ref={scrollDemoRef}>
        <div id={"main_scroll_list"}>
        {data?.pages?.map(v=> v == null ? [] : v.list || [])?.flat()?.map((item, index) => {
          return (
            <div
              className="feedDetailList"
              key={"feedDetailList" + index}
              id={item.id}
            >
              <FeedDetailList data={item} />
            </div>
          );
        })}
        </div>
      </main>

      <common.NavigationBar />
    </Styles.Container>
  );
}

export default FeedDetailPage;
