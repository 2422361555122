import React from "react";

//숫자 3자리수 콤마 코드
function numberWithCommas(x) {
  try {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } catch (e) {
    return "0";
  }
}

function changeTime(e, lang) {
  const hours = Math.floor(e / 60);
  const minutes = e % 60;

  return (
    lang ? <>
      <b>{hours || "--"}</b>시간 <b>{minutes || "--"}</b>분
    </>  : <>
      <b>{hours || "--"}</b>h <b>{minutes || "--"}</b>min
    </>
  );
}

// 검색어 하이라이팅
const highlighting = (v, keyword) => {
  if (v == null) return "";

  const text = v.split(keyword);

  return text.map((item, index) => {
    return (
      <React.Fragment key={"text" + index}>
        {index > 0 && <span className="highlighting">{keyword}</span>}
        {item}
      </React.Fragment>
    );
  });
};

function clickDetail(url) {
  if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: "OPENLINK", url: url })
    );
  } else {
    //기존 이동 페이지 or navigate(url);
    window.location.href = url;
  }
}

function getFilterStr(parameter) {
  let filter = new URLSearchParams(parameter).toString();
  return decodeURIComponent(filter);
}

function getSearchParam() {
  try {
    return JSON.parse(
      '{"' +
        decodeURI(
          window.location.search
            .substring(1)
            .replace(/&/g, '","')
            .replace(/=/g, '":"')
        ) +
        '"}'
    );
  } catch (e) {
    return {};
  }
}

//keyArray = { key : page, value : 1 };
function getQueryParamUrl(obj, isClear, isParamOnly) {
  try {
    obj = obj ? obj : {};
    let searchParam = isClear ? {} : getSearchParam();
    for (let key in obj) {
      if (obj[key] == null) {
        delete searchParam[key];
        continue;
      }
      searchParam[key] = obj[key];
    }
    if (isParamOnly) {
      return getFilterStr(searchParam);
    }
    return `${window.location.pathname}?${getFilterStr(searchParam)}`;
  } catch (e) {
    return {};
  }
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function callNative(type, url, state, data) {
  if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type, url, state, data })
    );
  } else {
    //기존 이동 페이지 or navigate(url);
    window.location.href = url;
  }
}

export {
  numberWithCommas,
  highlighting,
  getFilterStr,
  getSearchParam,
  getQueryParamUrl,
  getCookie,
  clickDetail,
  callNative,
  changeTime,
};

export default {
  numberWithCommas,
  highlighting,
  getFilterStr,
  getSearchParam,
  getQueryParamUrl,
  getCookie,
  clickDetail,
  callNative,
  changeTime,
};
