import { useAtom } from "jotai";
import { modalAtom } from "store/atoms";

export default function useModals() {
  const [findOutModal, setFindOutModal] = useAtom(modalAtom.findOutModalAtom);
  const [pushModal, setPushModal] = useAtom(modalAtom.pushModalAtom);
  const [shareModal, setShareModal] = useAtom(modalAtom.shareModalAtom);
  const [friendModal, setFriendModal] = useAtom(modalAtom.friendModalAtom);
  const [moreBottomSheetModal, setMoreBottomSheetModal] = useAtom(
    modalAtom.moreBottomSheetModalAtom
  );
  const [toastModal, setToastModal] = useAtom(modalAtom.toastModalAtom);
  const [feedDeleteModal, setFeedDeleteModal] = useAtom(
    modalAtom.feedDeleteModalAtom
  );
  const [subscriptionModal, setSubscriptionModal] = useAtom(
    modalAtom.subscriptionModalAtom
  );
  const [subscriptionCompleteModal, setSubscriptionCompleteModal] = useAtom(
    modalAtom.subscriptionCompleteModalAtom
  );
  const [accountDeleteModal, setAccountDeleteModal] = useAtom(
    modalAtom.accountDeleteModalAtom
  );

  const clearModals = () => {
    setFindOutModal({ show: false });
    setPushModal({ show: false });
    setShareModal({ show: false });
    setFriendModal({ show: false });
    setMoreBottomSheetModal({ show: false });
    setToastModal({ show: false });
    setFeedDeleteModal({ show: false });
    setSubscriptionModal({ show: false });
    setSubscriptionCompleteModal({ show: false });
    setAccountDeleteModal({ show: false });
  };

  const clearSubModals = (callback) => {
    setTimeout(() => {
      callback();
    }, [0]);
  };

  return {
    clearModals,
    findOutModal,
    setFindOutModal,
    pushModal,
    setPushModal,
    shareModal,
    setShareModal,
    friendModal,
    setFriendModal,
    moreBottomSheetModal,
    setMoreBottomSheetModal,
    toastModal,
    setToastModal,
    feedDeleteModal,
    setFeedDeleteModal,
    subscriptionModal,
    setSubscriptionModal,
    subscriptionCompleteModal,
    setSubscriptionCompleteModal,
    accountDeleteModal,
    setAccountDeleteModal,
  };
}
