import Styles from "./styles";
import usePolicy from "hooks/usePolicy";
import { ui } from "components";
import { useLanguage } from "hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

function AppDownload() {

  useEffect(()=>{
    let device = getMobileOperatingSystem();
    if(device == "IOS"){
      window.location.replace("https://www.apple.com/kr/app-store/")
    }else{
      window.location.replace("https://play.google.com/store/games?hl=ko/")
    }
  },[])

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "IOS";
    }

    return "unknown";
}

  return (
    <Styles.Container>
    </Styles.Container>
  );
}

export default AppDownload;
