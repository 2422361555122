import product from "./product";
import dummy from "./dummy";
import auth from "./auth";
import inquiry from "./inquiry";
import board from "./board";
import summary from "./summary";
import policy from "./policy";
import order from "./order";

export default {
  auth,
  product,
  dummy,
  inquiry,
  board,
  summary,
  policy,
  order,
};

export { auth, product, dummy, inquiry, board, summary, policy, order };
