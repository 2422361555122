import styled, { css } from "styled-components";

const colorTypes = {
  white: {
    color: "var(--black130)",
    backgroundColor: "#fff",
  },
  black120: {
    backgroundColor: "var(--black120)",
  },
  black110: {
    backgroundColor: "var(--black110)",
  },
  black100: {
    backgroundColor: "var(--black100)",
  },
  black90: {
    backgroundColor: "var(--black90)",
  },
  black80: {
    backgroundColor: "var(--black80)",
  },
  black70: {
    backgroundColor: "var(--black70)",
  },
  black60: {
    backgroundColor: "var(--black60)",
  },
  black50: {
    backgroundColor: "var(--black50)",
  },
  black40: {
    backgroundColor: "var(--black40)",
  },
};

const sizeTypes = {
  height52: {
    height: "52",
  },
  height50: {
    height: "50",
  },
};

//변수를 size 형태로 받아서 분기 처리가 가능합니다.
const colorTypeStyles = css`
  ${({ colorType }) => css`
    color: ${colorTypes[colorType] && colorTypes[colorType].color};
    background-color: ${colorTypes[colorType] &&
    colorTypes[colorType].backgroundColor};
  `}
`;
const sizeTypeStyles = css`
  ${({ sizeType }) => css`
    height: ${sizeTypes[sizeType] &&
    sizeTypes[sizeType].height &&
    "calc(100vw * (" + sizeTypes[sizeType].height + "/ 390))"};
  `}
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vw * (55 / 390));
  font-size: calc(100vw * (20 / 390));
  font-weight: 600;
  color: #fff;
  background-color: var(--black130);
  border-radius: calc(100vw * (5 / 390));

  &:disabled {
    background-color: var(--black30);
  }

  ${colorTypeStyles}
  ${sizeTypeStyles}
`;

export { Button };

export default {
  Button,
};
