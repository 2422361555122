import Styles from "./styles";

// img
import IconDelete from "resources/image/icon/icon_delete.svg";
import IconExport from "resources/image/icon/icon_export.svg";

function BasicInput({
  value,
  data,
  setInputs,
  onChange,
  readOnly,
  exportButtonEvent,
  onFocusEvent,
  onBlurEvent,
}) {
  const { id, labelText, type, name, placeholder } = data;

  const onChangeEvent = (e) => {
    const { name, value } = e.target;
    if (onChange) onChange(e);
    else setInputs((e) => ({ ...e, [name]: value }));
  };

  return (
    <Styles.Container>
      {labelText && <label htmlFor={id || name}>{labelText}</label>}

      <div className="inputWrap">
        <input
          type={type || "text"}
          id={id || name}
          name={name}
          placeholder={placeholder}
          value={value || data.value}
          readOnly={readOnly}
          onChange={onChangeEvent}
          onFocus={onFocusEvent}
          onBlur={onBlurEvent}
        />

        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            exportButtonEvent
              ? exportButtonEvent()
              : setInputs((e) => ({ ...e, [name]: "" }));
          }}
        >
          <img
            src={exportButtonEvent ? IconExport : IconDelete}
            alt="IconDelete"
          />
        </button>
      </div>
    </Styles.Container>
  );
}

export default BasicInput;
