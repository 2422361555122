import button from "./button";
import input from "./input";
import Pagination from "./Pagination";

export default {
  button,
  input,
  Pagination,
};

export { button, input, Pagination };
