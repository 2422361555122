import FeedList from "./FeedList";
import AdContainer from "./AdContainer";

export {
  FeedList,
  AdContainer,
};

export default {
  FeedList,
  AdContainer,
};
