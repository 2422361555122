import styled from "styled-components";

// img
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom.svg";
import IconArrowTop from "resources/image/icon/icon_arrow_top.svg";

const Container = styled.div`
  position: relative;
  cursor: pointer;

  & .nowSelect {
    display: flex;
    align-items: center;
    height: calc(100vw * (40 / 390));
    padding: 0 calc(100vw * (10 / 390));
    font-weight: 300;
    color: var(--black50);
    border-bottom: 1px solid var(--black130);
    background: url(${IconArrowBottom}) no-repeat
      calc(100% - calc(100vw * (10 / 390))) center / calc(100vw * (25 / 390));

    &.on {
      color: var(--black130);
    }

    &.focus {
      background: url(${IconArrowTop}) no-repeat
        calc(100% - calc(100vw * (10 / 390))) center / calc(100vw * (25 / 390));
    }
  }

  & .menuContainer {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    font-size: calc(100vw * (14 / 390));
    font-weight: 600;
    color: #fff;
    background-color: var(--black130);
    z-index: 2;

    & .optionList {
      display: flex;
      align-items: center;
      height: calc(100vw * (40 / 390));
      padding: 0 calc(100vw * (10 / 390));
    }
  }
`;

export { Container };

export default { Container };
