import { useEffect } from "react";

import Styles from './styles';

// img
import { ip } from "service/connector";

function AdContainer() {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error('AdSense error:', e);
    }
  }, []); // 빈 배열로 한 번만 실행

  return (
    <Styles.Container>
      <div className="adContainer">
        <ins 
        id={"ca-pub-2293642890651426"}
        className="adsbygoogle"
        style={{display:"block", width:"100vw" }}
        data-ad-format="fluid"
        data-ad-layout-key="-ip+a-w-1e+66"
        data-ad-client="ca-pub-2293642890651426"
        data-ad-slot="3438779689"></ins>
      </div>
    </Styles.Container>
  );
}

export default AdContainer;
