import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: 100dvh;

  & header {
    flex-shrink: 0;
    font-size: calc(100vw * (20 / 390));
    font-weight: 600;
    background-color: #fff;
    z-index: 8;

    & .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: calc(100vw * (65 / 390));
    }

    & button {
      width: calc(100vw * (21 / 390));
      height: calc(100vw * (21 / 390));
    }
  }

  & .tab {
    display: flex;
    background-color: var(--black130);

    & .tabList {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 0;
      height: calc(100vw * (50 / 390));
      font-size: calc(100vw * (18 / 390));
      font-weight: 300;
      color: var(--black80);
      cursor: pointer;

      &.on {
        font-weight: 600;
        color: #fff;

        span {
          position: relative;

          &::after {
            position: absolute;
            top: calc(100% + calc(100vw * (11 / 390)));
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            height: calc(100vw * (1.5 / 390));
            background-color: #fff;
            border-radius: 10px;
            content: "";
            z-index: 2;
          }
        }
      }
    }
  }

  & main {
    flex-grow: 1;
    max-height: calc(100vh - calc(100vw * (165 / 390)));
    max-height: calc(100dvh - calc(100vw * (165 / 390)));
    background-color: var(--black120);
    overflow: auto;

    & .feeListWrap {
      display: flex;
      flex-wrap: wrap;

      & .feedList {
        width: calc(100% / 3);
        cursor: pointer;
      }
    }
  }

  & .addButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    & button {
      width: calc(100vw * (37 / 390));
      height: calc(100vw * (37 / 390));
    }
  }
`;

export { Container };

export default { Container };
