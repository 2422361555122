import { useQuery } from "react-query";
import { api } from "service";

export default function usePolicy({ type }) {

  const getPolicy = useQuery(["policy", type], () => {
    return api.policy.getPolicy(type);
  }, {
    refetchOnWindowFocus: false,
    enabled: true
  });

  return {
    getPolicy
  };
}
