import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  z-index: 11;

  & .modalInner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100vw * (342 / 390));
    text-align: center;
    border-radius: calc(100vw * (15 / 390));
    overflow: hidden;

    & .closeButton {
      position: absolute;
      top: calc(100vw * (15 / 390));
      right: calc(100vw * (15 / 390));
      width: calc(100vw * (25 / 390));
      height: calc(100vw * (25 / 390));
    }

    & .top {
      padding: calc(100vw * (30 / 390)) 0 calc(100vw * (21 / 390));
      background-color: #fff;

      & .title {
        width: fit-content;
        margin: 0 auto calc(100vw * (10 / 390));
        padding: calc(100vw * (4 / 390)) calc(100vw * (21 / 390));
        font-weight: 300;
        border: 1px solid var(--black130);
        border-radius: calc(100vw * (100 / 390));
      }

      & .text {
        font-size: calc(100vw * (20 / 390));
        font-weight: 600;
      }
    }

    & .bottom {
      padding: calc(100vw * (23 / 390)) 0 calc(100vw * (20 / 390));
      background-color: var(--black130);

      & .buttonWrap {
        display: flex;
        justify-content: center;
        gap: calc(100vw * (12 / 390));
        margin-bottom: calc(100vw * (16 / 390));

        & button {
          width: calc(100vw * (130 / 390));
        }
      }

      & .guideText {
        font-size: calc(100vw * (14 / 390));
        font-weight: 300;
        color: var(--black40);
      }
    }
  }

  & .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(9, 9, 9, 0.8);
  }
`;

export { Container };

export default { Container };
