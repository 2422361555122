import connector from "service/connector";
import utils from "utils";

const getOrder = async (data) => {
  return await connector.authConnector(
    "order" + (data ? "?" + utils.etc.getFilterStr(data) : ""),
    "GET"
  );
};

export { getOrder };

export default {
  getOrder,
};
