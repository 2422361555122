import styled from "styled-components";

const Input = styled.input`
  display: none;

  &:checked + label {
    color: #fff;
    font-weight: 600;
    background-color: var(--black130);
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vw * (40 / 390));
  font-weight: 300;
  background-color: #fff;
  border: 1px solid var(--black130);
  border-radius: calc(100vw * (5 / 390));
  cursor: pointer;
`;

export { Input, Label };

export default { Input, Label };
