import { api } from "service";
import { useNavigate } from "react-router-dom";

export default function useInquiry() {
  const navigate = useNavigate();

  const postInquiry = async (data) => {
    const result = await api.inquiry.postInquiry(JSON.stringify(data));
    if (result.success) { navigate(-1) }
  };

  return {
    postInquiry,
  };
}
