import styled from "styled-components";

const Container = styled.main`
  & > .inner {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: 100dvh;
    padding: calc(100vw * (50 / 390)) 0;
  }

  & h1 {
    margin-bottom: calc(100vw * (55 / 390));
    font-size: calc(100vw * (25 / 390));
    font-weight: 600;
  }

  & .inputListWrap {
    width: calc(100vw * (333 / 390));
    margin: 0 auto calc(100vw * (70 / 390));

    & .inputList:not(:last-child) {
      margin-bottom: calc(100vw * (10 / 390));
    }
  }

  & .etcReason {
    width: calc(100vw * (333 / 390));
    margin: calc(100vw * (-50 / 390)) auto calc(100vw * (70 / 390));
  }

  & .etcReasonContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    min-height: 100dvh;
    padding-top: calc(100vw * (116 / 390));
    background-color: #fff;
    z-index: 2;

    & h1 {
      margin-bottom: calc(100vw * (126 / 390));
    }

    & .inputInner {
      width: calc(100vw * (333 / 390));
      margin: 0 auto;
    }
  }

  & .nextButton {
    margin-top: auto;
  }
`;

export { Container };

export default { Container };
