import { useQuery } from "react-query";
import { api } from "service";

export default function useOrder({ order }) {
  const orderQuery = useQuery(
    ["orderList", order],
    () => {
      return api.order.getOrder(order);
    },
    {
      refetchOnWindowFocus: true,
      enabled: !!order,
    }
  );

  return {
    orderQuery,
  };
}
