import Logo from "resources/image/logo.svg";
import { IMAGEPATH } from 'service/connector';
import { useState } from 'react';

function ProgressiveImage({ lowQualitySrc, highQualitySrc, alt, crossOrigin }) {
  const [load, setLoad] = useState(false);

  return (
    <>
      {!load &&
        <img
          style={{ position: 'absolute',  }}
          src={IMAGEPATH + lowQualitySrc}
          crossOrigin={crossOrigin}
          alt={alt}
        />
      }
      <img
        style={{ position: 'absolute',  }}
        src={IMAGEPATH + highQualitySrc + "/download"}
        crossOrigin={crossOrigin}
        alt={alt}
        onLoad={() => setLoad(true)}
      />
    </>
  );
}

export default ProgressiveImage;
