import styled from "styled-components";

const Container = styled.main`
  & .inner {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: 100dvh;
    padding-top: calc(100vw * (98 / 390));
  }

  & .listWrap {
    border-top: calc(100vw * (1 / 390)) solid var(--black30);

    & .list {
      padding: calc(100vw * (22 / 390)) calc(100vw * (20 / 390))
        calc(100vw * (20 / 390));
      border-bottom: calc(100vw * (1 / 390)) solid var(--black30);

      & .top {
        display: flex;
        align-items: center;
        margin-bottom: calc(100vw * (31 / 390));
        font-size: calc(100vw * (20 / 390));
        font-weight: 300;

        & b {
          margin-right: calc(100vw * (5 / 390));
          font-weight: 600;
        }

        & .price {
          margin-left: auto;
          font-size: calc(100vw * (25 / 390));
          font-weight: 600;
        }
      }

      & .date {
        font-weight: 300;
        color: var(--black80);
      }

      & .now {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vw * (33 / 390));
        margin-top: calc(100vw * (15 / 390));
        font-size: calc(100vw * (14 / 390));
        font-weight: 300;
        border: calc(100vw * (1 / 390)) solid var(--black130);
        border-radius: calc(100vw * (5 / 390));
      }
    }
  }
`;

export { Container };

export default { Container };
