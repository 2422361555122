import styled from "styled-components";

import bgLogin from "resources/image/bg_login.png";

const Container = styled.main`
  & .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    min-height: 100dvh;
    padding: calc(100vw * (98 / 390)) 0 calc(100vw * (50 / 390));
  }

  & .logo {
    position: relative;
    width: calc(100vw * (243 / 390));
    height: calc(100vw * (47 / 390));

    &::before {
      position: absolute;
      top: calc(100vw * (-50 / 390));
      left: 50%;
      transform: translateX(-50%);
      width: calc(100vh * (342 / 844));
      width: calc(100dvh * (342 / 844));
      height: calc(100vh * (413 / 844));
      height: calc(100dvh * (413 / 844));
      background: url(${bgLogin}) no-repeat center / cover;
      content: "";
      z-index: -1;
    }
  }

  & p {
    margin: auto 0 calc(100vw * (23 / 390));
    font-size: calc(100vw * (20 / 390));
    font-weight: 600;
  }

  & ul {
    width: 100%;

    li {
      &:not(:last-child) {
        margin-bottom: calc(100vw * (8 / 390));
      }

      &:first-child button {
        background-color: #ffeb52;
      }

      &:nth-child(2) button {
        background-color: #efefef;
      }

      &:last-child button {
        color: #fff;
        background-color: var(--black130);
      }

      & button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: calc(100vw * (10 / 390));
        height: calc(100vw * (55 / 390));
        font-weight: 600;
        border-radius: calc(100vw * (5 / 390));

        & .icon {
          display: inline-block;
          width: calc(100vw * (24 / 390));
          height: calc(100vw * (24 / 390));
        }
      }
    }
  }

  & .askLink {
    margin-top: calc(100vw * (20 / 390));
    font-size: calc(100vw * (14 / 390));
    font-weight: 300;
    color: var(--black80);
    text-decoration-line: underline;
    text-underline-offset: calc(100vw * (2 / 390));
  }
`;

export { Container };

export default { Container };
