import Styles from "./styles";
import { ui } from "components";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useInquiry, useLanguage } from "hooks";

function AskPage() {
  const navigate = useNavigate();
  const inquiryInfo = useInquiry();
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix == "";

  const [inputs, setInputs] = useState({
    email: "",
    textarea: "",
  });
  const [findOut, setfindOut] = useState("");
  const [select, setSelect] = useState("");
  const [privacy, setPrivacy] = useState(true);
  const [use, setUse] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);

  const data = [
    {
      id: "email",
      labelText: languageQuarter
        ? "답변 받으실 이메일"
        : "Email address",
      name: "email",
      value: inputs.email,
      placeholder: "stamfit@gmail.com",
    },
  ];

  const optionList = languageQuarter ? [
    { text: "로그인/회원가입" },
    { text: "오류 발생" },
    { text: "기능 제안" },
    { text: "광고 제휴" },
    { text: "기타" },
  ] : [
    { text: "Log In / Sign Up" },
    { text: "Bug" },
    { text: "Request" },
    { text: "Partnership" },
    { text: "Other" },
  ];

  useEffect(() => {
    if (
      inputs.email !== "" &&
      select !== "" &&
      inputs.textarea !== "" &&
      privacy &&
      use
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [inputs, select, privacy, use]);

  const onNext = () => {
    inquiryInfo.postInquiry({
      content: inputs.textarea,
      email: inputs.email,
      title: select,
    });
  };

  return (
    <Styles.Container>
      <div className="inner">
        <ui.button.PrevButton text={languageQuarter ? "문의하기" : "Contact Us"} />

        <ul className="askListWrap">
          <li className="askList">
            <ui.input.BasicInput
              data={data[0]}
              value={inputs.email}
              setInputs={setInputs}
            />
          </li>

          <li className="askList">
            <label>{languageQuarter ? "문의 유형" : "Type of Inquiry"}</label>
            <ui.input.BasicSelect
              placeholder={languageQuarter ? "카테고리 선택" : "Category"}
              optionList={optionList}
              select={select}
              setSelect={setSelect}
            />
          </li>

          <li className="askList">
            <label>{languageQuarter ? "문의 내용" : "Inquiries"}</label>
            <div className="textarea">
              <textarea
                value={inputs.textarea}
                name="textarea"
                onChange={(e) => {
                  const { name, value } = e.target;
                  setInputs((e) => ({ ...e, [name]: value }));
                }}
                maxLength={1000}
                placeholder={languageQuarter
                  ? "내용을 입력해주세요."
                  : "Please enter the inquiry details."
                }
              ></textarea>

              <p className="textNumber">{inputs.textarea.length}/1000</p>
            </div>
          </li>
        </ul>

        <ul className="termsListWrap">
          <li className="termsList">
            <ui.input.BasicCheckbox
              id={"privacy"}
              checkText={languageQuarter ? "[필수] 개인정보 처리 동의" : "[Required] Privacy Policy"}
              checked={privacy}
              onChange={(e) => {
                setPrivacy(e.target.checked);
              }}
            />
            <button
              type="button"
              className="detailTermsButton"
              onClick={() => {
                navigate("/terms/privacy");
              }}
            >
              {languageQuarter ? "자세히보기" : "more details"}
            </button>
          </li>
          <li className="termsList">
            <ui.input.BasicCheckbox
              id={"use"}
              checkText={languageQuarter ? "[필수] 이용약관 동의" : "[Required] Terms & Conditions"}
              checked={use}
              onChange={(e) => {
                setUse(e.target.checked);
              }}
            />
            <button
              type="button"
              className="detailTermsButton"
              onClick={() => {
                navigate("/terms/use");
              }}
            >
              {languageQuarter ? "자세히보기" : "more details"}
            </button>
          </li>
        </ul>

        <div className="askButton">
          <ui.button.BasicButton
            buttonText={languageQuarter ? "문의하기" : "Done"}
            disabled={isDisabled}
            onClick={onNext}
          />
        </div>
      </div>
    </Styles.Container>
  );
}

export default AskPage;
