export default function useLanguage() {
  const setLanguage = (item) => {
    return sessionStorage.setItem("language", item);
  };

  const lang = sessionStorage.getItem("language");
  const suffix = lang == null || lang == "" || String(lang).toLocaleLowerCase().includes("ko") || String(lang).toLocaleLowerCase().includes("kr") ? "" : lang;

  return {
    setLanguage,
    suffix,
  };
}
