import { atom } from "jotai";

const findOutModalAtom = atom({ show: false, zIndex: 20 });
const pushModalAtom = atom({ show: false, zIndex: 20 });
const shareModalAtom = atom({ show: false, zIndex: 20 });
const friendModalAtom = atom({ show: false, zIndex: 20 });
const moreBottomSheetModalAtom = atom({ show: false, zIndex: 20 });
const toastModalAtom = atom({ show: false, zIndex: 20 });
const feedDeleteModalAtom = atom({ show: false, zIndex: 20 });
const subscriptionModalAtom = atom({ show: false, zIndex: 20 });
const subscriptionCompleteModalAtom = atom({ show: false, zIndex: 20 });
const accountDeleteModalAtom = atom({ show: false, zIndex: 20 });

export {
  findOutModalAtom,
  pushModalAtom,
  shareModalAtom,
  friendModalAtom,
  moreBottomSheetModalAtom,
  toastModalAtom,
  feedDeleteModalAtom,
  subscriptionModalAtom,
  subscriptionCompleteModalAtom,
  accountDeleteModalAtom,
};

export default {
  findOutModalAtom,
  pushModalAtom,
  shareModalAtom,
  friendModalAtom,
  moreBottomSheetModalAtom,
  toastModalAtom,
  feedDeleteModalAtom,
  subscriptionModalAtom,
  subscriptionCompleteModalAtom,
  accountDeleteModalAtom,
};
