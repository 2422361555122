//img
import DummyImg01 from "resources/image/img_dummy_01.jpg";
import DummyImg02 from "resources/image/img_dummy_02.jpg";
import DummyImg03 from "resources/image/img_dummy_03.jpg";
import DummyImg04 from "resources/image/img_dummy_04.jpg";
import DummyLineImg01 from "resources/image/img_dummy_line.png";

const feedList = [
  // type: 0 - 눈바디 / 1 - 식사 / 2 - 운동 / 3 - 기타
  {
    type: 0,
    stamp: 1,
    img: DummyImg03,
    date: "2024/05/25",
    time: "14:00",
    detail: {
      kg: 44,
      size: "감소",
      note: "afald",
    },
  },
  {
    type: 0,
    stamp: 0,
    img: DummyImg03,
    date: "2024/05/25",
    time: "14:00",
    tall: true,
    detail: {
      kg: 44,
      size: "동일",
    },
  },
  {
    type: 0,
    stamp: 0,
    img: DummyImg03,
    date: "2024/05/25",
    time: "14:00",
    detail: {
      kg: 44,
      size: "증가",
    },
  },
  {
    type: 0,
    stamp: 1,
    img: DummyImg03,
    date: "2024/05/25",
    time: "14:00",
  },
  {
    type: 1,
    stamp: 1,
    img: DummyImg01,
    date: "2024/05/25",
    time: "14:00",
    detail: {
      plusKcal: 400,
      meals:
        "김치볶음밥, 단무지, 라면 국물, 김치볶음밥, 단무지, 라면 국물김치볶음밥, 단무지, 라면 국물김치볶음밥, 단무지, 라면 국물",
      mealInfo: {
        carbohydrate: 30,
        protein: 30,
        fat: 10,
        amount: 65,
        satiety: "가볍게",
      },
      note: "건강한 음식을 먹기 시작하니 입맛이 건강해진다.",
    },
  },
  {
    type: 2,
    img: DummyImg02,
    stamp: 1,
    date: "2024/05/25",
    time: "14:00",
    detail: {
      minusKcal: 400,
      exercise: "자전거 타기, 스쿼트 100개",
      exerciseTime: "75분",
    },
  },
  {
    type: 3,
    stamp: 1,
    img: DummyImg04,
    date: "2024/05/25",
    time: "14:00",
    detail: {
      etcTitle: "감사일기 쓰기",
      emotion: "만족",
      note: "건강한 음식을 먹기 시작하니 입맛이 건강해진다. 건강한 음식을 먹기 시작하니 입맛이 건강해진다.건강한 음식을 먹기 시작하니 입맛이 건강해진다.",
    },
  },
];

const userInfo = {
  subscription: false,
  date: "2024년 7월 20일",
};

const paymentList = [
  {
    period: "월간",
    startDate: "2024/07/01",
    endDate: "2024/07/30",
    price: 6900,
    now: true,
  },
  {
    period: "연간",
    startDate: "2024/07/01",
    endDate: "2025/06/30",
    price: 6900,
  },
];

const comparisonData = {
  before: {
    date: "2024/07/01",
    lineImg: DummyLineImg01,
    weight: 53,
  },
  after: {
    date: "2024/07/30",
    lineImg: DummyLineImg01,
    weight: 43,
  },
};

const photoList = [
  {
    id: 0,
    stamp: 1,
    img: DummyImg03,
    date: "2024/05/24",
    time: "14:00",
    detail: {
      kg: 54,
      size: "감소",
      note: "afald",
    },
  },
  {
    id: 1,
    stamp: 0,
    img: DummyImg03,
    date: "2024/05/25",
    time: "14:00",
    tall: true,
    detail: {
      kg: 44,
      size: "동일",
    },
  },
  {
    id: 2,
    stamp: 0,
    img: DummyImg03,
    date: "2024/05/25",
    time: "14:00",
    detail: {
      kg: 44,
      size: "증가",
    },
  },
  {
    id: 3,
    stamp: 1,
    img: DummyImg03,
    date: "2024/05/25",
    time: "14:00",
  },
];

const todayData = {
  date: "2024년 7월 7일",
  bodyChange: "동일",
  weight: "55.5",
  weightChange: "0",
  nutrient: {
    carbohydrate: 400,
    protein: 300,
    fat: 300,
  },
  burnedCalories: 2000,
  exerciseTime: 90,
};

const weeklyData = {
  date: "2024년 7월 8일 - 2024년 7월 14일",
  bodyChange: "감소",
  weightChange: "",
  nutrient: {
    carbohydrate: 800,
    protein: 800,
    fat: 400,
  },
  burnedCalories: 5000,
  exerciseTime: 150,
};

const monthlyData = {
  date: "2024년 7월",
  bodyChange: "감소",
  weightChange: "",
  nutrient: {
    carbohydrate: 1000,
    protein: 300,
    fat: 200,
  },
  burnedCalories: 10000,
  exerciseTime: 1200,
};

export {
  feedList,
  userInfo,
  paymentList,
  comparisonData,
  photoList,
  todayData,
  weeklyData,
  monthlyData,
};

export default {
  feedList,
  userInfo,
  paymentList,
  comparisonData,
  photoList,
  todayData,
  weeklyData,
  monthlyData,
};
