import styled from "styled-components";

const Container = styled.main`
  & .inner {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: 100dvh;
    padding-top: calc(100vw * (107 / 390));
  }

  & .list {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (20 / 390));
    }

    & button {
      font-weight: 300;
    }
  }
`;

export { Container };

export default { Container };
