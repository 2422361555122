import Styles from "./styles";
import ui from "components/ui";
import { useState } from "react";
import { useLanguage, useProduct } from "hooks";

// img
import IconDelete from "resources/image/icon/icon_delete_white.svg";
import utils from "utils";
import { callNative } from "utils/etc";

function SubscriptionModal({ modalClose }) {
  const productInfo = useProduct();
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix == "";

  const [select, setSelect] = useState(2);

  const benefitList = languageQuarter
    ? ["광고 제거", "사진 로고 삭제 가능", "통계 기능 열람"]
    : ["Remove Ads", "Remove Logo", "View Statisics Feature"];
  const radioList = productInfo.getProduct?.data?.data || [];

  return (
    <Styles.Container>
      <button type="button" className="closeButton" onClick={()=>{ if(modalClose)modalClose();else callNative("NATIVEBACK"); }}>
        <img src={IconDelete} alt="IconDelete" />
      </button>

      <div className="inner">
        <div className="tag">{languageQuarter ? "12,000명이 이용중인" : "12,000 people are using"}</div>

        <p className="title">{languageQuarter ? "광고없는 ‘STAMFIT’" : "Ad-free ‘STAMFIT’"}</p>

        {languageQuarter
          ? <p className="text">
            STAMFIT의 모든 것을 <span>끊김없이</span> 즐기세요
          </p>
          : <p className="text">
            Enjoy STAMFIT without interruption
          </p>
        }

        <ul className="benefitListWrap">
          {benefitList.map((item, index) => {
            return (
              <li className="benefitList" key={"benefitList" + index}>
                {item}
              </li>
            );
          })}
        </ul>

        <ul className="radioListWrap">
          {radioList.map((item, index) => {
            return (
              <li className="radioList" key={"radioList" + index}>
                <input
                  type="radio"
                  id={item.id}
                  name="subscription"
                  checked={select == item.id ? true : false}
                  onChange={(e) => {
                    setSelect(e.target.id);
                  }}
                />
                <label htmlFor={item.id}></label>

                <div className="radioTag">
                  {languageQuarter
                    ? item.sale == 0 ? "기본 가격" : item.sale + "% 할인"
                    : item.sale == 0 ? "Basic Price" : item.sale + "% Off"
                  }
                </div>

                <div className="checkbox"></div>

                <p className="period">{item["title" + languageInfo.suffix]}</p>

                {/* {!languageQuarter && item.keyword && <p className="through_en">{radioList[0].subtitle_en + " / month"}</p>} */}
                {/* {item.keyword && <p className={"beforePrice" + (languageQuarter ? "" : "_en")}>{item["keyword" + languageInfo.suffix]}</p>} */}

                <p className="price">{item["subtitle" + languageInfo.suffix]}</p>
              </li>
            );
          })}
        </ul>

        <div className="nextButton" style={{marginBottom:14, textDecoration:'underline'}} onClick={()=>{ callNative("PAYRESTORE"); }}>
        {languageQuarter
          ? <p className="bottomText">
            월간 이용 구매 복원하기
          </p>
          : <p className="bottomText">
            Restoring purchases
          </p>
        }
        </div>

        {/* {languageQuarter
          ? <p className="bottomText">
            커피 한 잔 값으로, 자기 관리하는 사람이 될 수 있어요
          </p>
          : <p className="bottomText">
            For the price of a cup of coffee,<br />
            you can become a self-manager
          </p>
        } */}

        <div className="nextButton">
          <ui.button.BasicButton
            buttonText={languageQuarter ? "다음" : "Next"}
            colorType={"white"}
            onClick={() => {
              if (radioList.filter((value) => value.id == select)[0])
                utils.etc.callNative(
                  "PAY",
                  "PayPage",
                  radioList.filter((value) => value.id == select)[0]
                );
            }}
          />
        </div>
      </div>
    </Styles.Container>
  );
}

export { SubscriptionModal };

export default SubscriptionModal;
