import Styles from "./styles";
import { date } from "utils";
import { useRef } from "react";
import { layout } from "components";
import { IMAGEPATH } from "service/connector";
import { useLanguage, useModals } from "hooks";

// img
import IconMore from "resources/image/icon/icon_more.svg";

function FeedDetailList({ data }) {
  const captureRef = useRef(null);
  const modalOption = useModals();
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix == "";

  const typeData = (type) => ({
    1: ['감소', '동일', '증가'],
    2: ['가볍게', '적당히', '배부르게', '과하게'],
    4: ['만족', '보통', '불만족'],
  }[type]);
  const typeDataEN = (type) => ({
    1: ['Decrease', 'Same', 'Increase'],
    2: ['Lightly', 'Moderately', 'Heavily', 'Excessively'],
    4: ['Satisfied', 'Neutral', 'Dissatisfied'],
  }[type]);

  const renderDetail = (type) => {
    // type: 1 - 눈바디 / 2 - 식사 / 3 - 운동 / 4 - 기타
    switch (type) {
      case 1:
        return (
          <>
            {data.weight && <p className="weight">{data.weight}kg</p>}
            {data?.state &&
              <div className="body">
                <div className="title">{languageQuarter ? "눈바디 사이즈 변화" : "Body size change"}</div>
                <div className="size">{languageQuarter
                  ? data?.state
                  : typeDataEN(type)[typeData(type).indexOf(data?.state)]
                }</div>
              </div>
            }
          </>
        );
      case 2:
        return (
          <>
            <p className="meals">{data?.title}</p>

            <ul className="mealInfoListWrap">
              <li className="mealInfoList">
                <div className="title">{languageQuarter ? "탄" : "C"}</div>
                {Math.round(data?.carbohydrate_per * (data?.eat_range / 100))}g
              </li>

              <li className="mealInfoList">
                <div className="title">{languageQuarter ? "단" : "P"}</div>
                {Math.round(data?.protein_per * (data?.eat_range / 100))}g
              </li>

              <li className="mealInfoList">
                <div className="title">{languageQuarter ? "지" : "F"}</div>
                {Math.round(data?.fat_per * (data?.eat_range / 100))}g
              </li>

              <li className="mealInfoList">
                <div className="title">{languageQuarter ? "식사량" : "Amount"}</div>
                {data?.eat_range}%
              </li>

              {data?.state &&
                <li className="mealInfoList">
                  <div className="title">{languageQuarter ? "포만감" : "Fullness"}</div>
                  {languageQuarter
                    ? data?.state
                    : typeDataEN(type)[typeData(type).indexOf(data?.state)]
                  }
                </li>
              }
            </ul>
          </>
        );
      case 3:
        return (
          <>
            <p className="exercise">{data?.work_list?.map(v => v.title).join(', ')}</p>

            <div className="exerciseInfo">
              <div className="title">{languageQuarter ? "운동시간" : "Total time"}</div>
              <div className="exerciseTime">{
                data?.work_list
                  ?.map(v => parseInt(v.work_min))
                  ?.reduce((a, b) => a + b, 0)
              }{languageQuarter ? "분" : "min"}</div>
            </div>
          </>
        );
      case 4:
        return (
          <>
            <p className="etcTitle">{data?.title}</p>

            {data?.state &&
              <div className="emotionWrap">
                <div className="title">{languageQuarter ? "감정" : "Emotion"}</div>
                <div className="emotion">{languageQuarter
                  ? data?.state
                  : typeDataEN(type)[typeData(type).indexOf(data?.state)]
                }</div>
              </div>
            }
          </>
        );
    }
  };

  return (
    <Styles.Container>
      <div className="timeContainer">
        <div className="bg">
          <img src={IMAGEPATH + data.image} alt="timeBg" />
        </div>

        <p className="date">{data?.created_at?.split(" ")[0]
          ? date.getDateType(data?.created_at?.split(" ")[0], "/")
          : "----/--/--"
        }</p>
        <p className="time">{data?.created_at?.split(" ")[1].slice(0, 5) || "--:--"}</p>

        <button
          type="button"
          className="moreButton"
          onClick={() => {
            modalOption.setMoreBottomSheetModal((e) => {
              e.show = true;
              e.data = {
                data: data,
                captureRef: captureRef
              };

              return { ...e };
            });
          }}
        >
          <img src={IconMore} alt="IconMore" />
        </button>
      </div>

      <layout.FeedList data={data} big captureRef={captureRef}/>

      <div className="detailContainer">
        <>
          {renderDetail(parseInt(data.category_info_id))}

          {data?.memo && (
            <div className="noteWrap">
              <div className="noteTitle">note</div>
              <p className="note">{data?.memo}</p>
            </div>
          )}
        </>

        <button
          type="button"
          className="moreButton"
          onClick={() => {
            modalOption.setMoreBottomSheetModal((e) => {
              e.show = true;
              e.data = {
                data: data,
                captureRef: captureRef
              };

              return { ...e };
            });
          }}
        >
          <img src={IconMore} alt="IconMore" />
        </button>
      </div>
    </Styles.Container>
  );
}

export default FeedDetailList;
