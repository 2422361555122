import Styles from "./styles";
import { useSummary } from "hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { IMAGEPATH } from "service/connector";

// img
import ImgDefaultLine from "resources/image/img_default_line.png";
import utils from "utils";

function Comparison() {
  const navigate = useNavigate();
  const location = useLocation();

  const summaryInfo = useSummary({
    compare: {
      after_id: 0,
      before_id: 0,
    },
  });
  const compareData = summaryInfo.compareSummary?.data?.data || {};

  const bodyList = [
    {
      title: "BEFORE",
      lineImg: location.state?.[0]?.outline
        ? IMAGEPATH + location.state?.[0]?.outline
        : (compareData?.before_data?.outline_image &&
            IMAGEPATH + compareData?.before_data?.outline_image)
    },
    {
      title: "AFTER",
      lineImg: location.state?.[1]?.outline
        ? IMAGEPATH + location.state?.[1]?.outline
        : (compareData?.after_data?.outline_image &&
            IMAGEPATH + compareData?.after_data?.outline_image)
    },
  ];

  const dateList = [
    {
      date:
        location.state?.[0]?.date ||
        utils.date.getDateType(compareData?.before_data?.created_at, "/") ||
        "YYYY/MM/DD",
      weight:
        location.state?.[0]?.weight ||
        compareData?.before_data?.weight?.replace("kg", "") ||
        "--",
    },
    {
      date:
        location.state?.[1]?.date ||
        utils.date.getDateType(compareData?.after_data?.created_at, "/") ||
        "YYYY/MM/DD",
      weight:
        location.state?.[1]?.weight ||
        compareData?.after_data?.weight?.replace("kg", "") ||
        "--",
    },
  ];

  return (
    <Styles.Container>
      <div className="inner">
        <div className="bodyContainer">
          {bodyList.map((item, index) => {
            return (
              <div className="bodyWrap" key={"bodyWrap" + index}>
                <p className="title roboto">{item.title}</p>

                <div className={"lineImgWrap"}>
                  <img src={item.lineImg || ImgDefaultLine} alt="lineImg"/>
                </div>
              </div>
            );
          })}
        </div>

        <div className="dateContainer">
          {dateList?.map((item, index) => {
            return (
              <div
                className="dateWrap roboto"
                key={"dateWrap" + index}
                onClick={() => {
                  navigate("/comparison-select");
                }}
              >
                <p className="date">{item.date}</p>
                <p className="weight">{item.weight}kg</p>
              </div>
            );
          })}
        </div>
      </div>

      {/* <div className="adArea"></div> */}
    </Styles.Container>
  );
}

export default Comparison;
