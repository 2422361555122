import Styles from "./styles";
import components from "./components";
import { ui } from "components";
import { useLanguage } from "hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function ComparisonPage() {
  const { id } = useParams();
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix == "";

  const location = useLocation();
  const navigate = useNavigate();

  const pathName = location.pathname.split("/")[2];

  const tabList = [
    {
      text: languageQuarter ? "비교" : "Comparison",
      id: "comparison",
    },
    {
      text: languageQuarter ? "통계" : "Statistics",
      id: "statistics",
    },
  ];

  return (
    <Styles.Container>
      <ui.button.PrevButton
        text={languageQuarter ? "분석" : "Analysis"}
        prevEvent={() => navigate("/")}
      />

      <ul className="tab inner">
        {tabList.map((item, index) => {
          return (
            <li
              className={"tabList" + (pathName === item.id ? " on" : "")}
              key={"tabList" + index}
              onClick={() => {
                navigate(`/comparison/${item.id}`);
              }}
            >
              {item.text}
            </li>
          );
        })}
      </ul>

      {id === "comparison" && <components.Comparison />}

      {id === "statistics" && <components.Statistics />}
    </Styles.Container>
  );
}

export default ComparisonPage;
