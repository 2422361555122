import styled from "styled-components";

const Container = styled.div`
  position: relative;
  padding-top: calc(100vw * (70 / 390));
  color: #fff;

  & .moreButton {
    width: calc(100vw * (25 / 390));
    height: calc(100vw * (25 / 390));
  }

  & .timeContainer {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: calc(100vw * (70 / 390));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--black70);
    border-bottom: 1px solid var(--black70);
    overflow: hidden;
    z-index: 2;

    &::after,
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &::after {
      background-color: rgba(9, 9, 9, 0.8);
      content: "";
      z-index: -1;
    }

    & .bg {
      filter: blur(calc(100vw * (40 / 390)));
      z-index: -2;
      overflow: hidden;
    }

    & .date {
      margin-bottom: calc(100vw * (2 / 390));
      font-weight: 300;
    }

    & .time {
      font-weight: 600;
    }

    & .moreButton {
      position: absolute;
      top: 50%;
      right: calc(100vw * (24 / 390));
      transform: translateY(-50%);
    }
  }

  & .detailContainer {
    padding: calc(100vw * (20 / 390)) 0 calc(100vw * (20 / 390))
      calc(100vw * (24 / 390));
    background-color: var(--black130);

    & .weight,
    .meals,
    .exercise,
    .etcTitle {
      margin: 0 calc(100vw * (24 / 390)) calc(100vw * (15 / 390)) 0;
      font-weight: 600;
    }

    & .body,
    .mealInfoList,
    .exerciseInfo,
    .emotionWrap {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      gap: calc(100vw * (5 / 390));
      margin-bottom: calc(100vw * (25 / 390));
      font-size: calc(100vw * (14 / 390));
      font-weight: 300;

      & .title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vw * (23 / 390));
        padding: 0 calc(100vw * (5 / 390));
        font-weight: 600;
        color: var(--black130);
        background-color: #fff;
      }
    }

    & .mealInfoListWrap {
      display: flex;
      gap: calc(100vw * (20 / 390));
      padding-right: calc(100vw * (24 / 390));
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    & .noteWrap {
      display: flex;
      gap: calc(100vw * (8 / 390));
      margin: 0 calc(100vw * (24 / 390)) calc(100vw * (20 / 390)) 0;
      font-size: calc(100vw * (14 / 390));

      & .noteTitle {
        font-weight: 600;
      }

      & .note {
        font-weight: 300;
        color: var(--black30);
      }
    }

    & .moreButton {
      display: block;
      margin: 0 calc(100vw * (24 / 390)) 0 auto;
    }
  }
`;

export { Container };

export default { Container };
