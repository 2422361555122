import Styles from "./styles";
import { useState } from "react";
import { ui, layout } from "components";
import { useNavigate } from "react-router-dom";
import { getDateType } from "utils/date";
import { useBoard, useLanguage } from "hooks";

// img
import IconArrowLeft from "resources/image/icon/icon_arrow_left_black.svg";
import IconArrowRight from "resources/image/icon/icon_arrow_right_black.svg";
import utils from "utils";

function ComparisonSelectPage() {
  const navigate = useNavigate();
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix == "";

  const [targetDate, setTragetDate] = useState(new Date());
  const [year, setYear] = useState(new Date().getFullYear());
  const boardInfo = useBoard({
    board: {
      category_info_id: 1,
      page: 1,
      recordSize: 999,
      started_at: utils.date.getRangeDate(targetDate, true),
      ended_at: utils.date.getRangeDate(targetDate, false),
    },
    group: {
      started_at: utils.date.getDateType(new Date(year + "-01-01")),
      ended_at: utils.date.getRangeDate(new Date(year + "-12-30")),
    },
  });

  const groupList = boardInfo.getGroup.data?.data || [];
  const data = boardInfo.getBoard?.data?.list || [];

  const [isDateOpen, setIsDateOpen] = useState(false);
  const [check, setCheck] = useState([]);

  const yearList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const checkedPhotos = data.filter(
    (photo) => check.filter((value) => value.id == photo.id)[0]
  );
  const before = check[0]; //checkedPhotos.filter((order) => order.id == check[0]?.id)[0];
  const after = check[1]; //checkedPhotos.filter((order) => order.id == check[1]?.id)[0];

  const dateList = [
    {
      title: "BEFORE",
      date: before
        ? getDateType(before?.created_at?.split(" ")[0], "/")
        : "YYYY/MM/DD",
      weight: before?.weight || "--",
      outline: before?.outline_image || null,
    },
    {
      title: "AFTER",
      date: after
        ? getDateType(after?.created_at?.split(" ")[0], "/")
        : "YYYY/MM/DD",
      weight: after?.weight || "--",
      outline: after?.outline_image || null,
    },
  ];

  function compareYear(month) {
    return (
      groupList.filter((value) => {
        let y = value.created_at.split("-")[0];
        let m = value.created_at.split("-")[1];
        return y == year && month == m;
      })[0] == null
    );
  }

  function changeYear(number) {
    setYear((v) => v + number);
  }

  // 체크박스 단일 선택
  const handleSingleCheck = (checked, item) => {
    if (check.length == 2) {
      setCheck([]);
    } else if (checked && check.length < 2) {
      // 단일 선택 시 체크된 아이템을 배열에 추가
      setCheck(check.concat(item));
    } else {
      // 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
      setCheck(check.filter((el) => el.id !== item.id));
    }
  };

  const monthName = (month) => {
    if(month == 1)  return "January"
    else if(month == 2) return "February" 
    else if(month == 3) return "March"
    else if(month == 4) return "April"
    else if(month == 5) return "May"
    else if(month == 6) return "June"
    else if(month == 7) return "July"
    else if(month == 8) return "August"
    else if(month == 9) return "September"
    else if(month == 10) return "October"
    else if(month == 11) return "November"
    else if(month == 12) return "December"
  }

  return (
    <Styles.Container>
      <div className="top">
        <ui.button.PrevButton />

        <p
          className="date"
          onClick={() => {
            setIsDateOpen(!isDateOpen);
          }}
        >
          {/* 데이터의 첫번째 사진의 날짜 (없으면 비워주세요) */}
          {languageQuarter
            ? `${targetDate.getFullYear()}년
              ${String(targetDate.getMonth() + 1).padStart(2, 0)}월`
            : `${monthName(String(targetDate.getMonth() + 1).padStart(2, 0))}
              ${targetDate.getFullYear()}`
          }
        </p>

        <button
          type="button"
          className="completeButton"
          onClick={() => {
            navigate("/comparison/comparison", { state: dateList });
          }}
        >
          {languageQuarter ? "완료" : "Done"}
        </button>
      </div>

      {isDateOpen && (
        <div className="dateSelectContainer">
          <div className="yearSelectWrap">
            <button
              type="button"
              onClick={() => {
                changeYear(-1);
              }}
            >
              <img src={IconArrowLeft} alt="IconArrowLeft" />
            </button>
            {year}
            <button
              type="button"
              onClick={() => {
                changeYear(1);
              }}
            >
              <img src={IconArrowRight} alt="IconArrowRight" />
            </button>
          </div>

          <ul className="yearListWrap">
            {yearList.map((item, index) => {
              return (
                <li className="yearList" key={"yearList" + index}>
                  <button
                    type="button"
                    disabled={compareYear(item)}
                    onClick={() => {
                      setIsDateOpen(false);
                      setTragetDate(new Date(year + "-" + String(item).padStart(2,0) + "-" + "01"));
                    }}
                  >
                    {languageQuarter
                      ? `${item}월`
                      : `${monthName(item).slice(0, 3)}`
                    }
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      <div className="photoContainer">
        <ul className="photoListWrap">
          {data?.map((item, index) => {
            return (
              <li className="photoList" key={"photoList" + index}>
                <input
                  type="checkbox"
                  id={item.id}
                  checked={
                    check.filter((value) => value.id == item.id)[0]
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    handleSingleCheck(e.target.checked, item);
                  }}
                />
                <label
                  htmlFor={item.id}
                  className={
                    check[0]?.id === item.id
                      ? "before"
                      : check[1]?.id === item.id
                      ? "after"
                      : ""
                  }
                ></label>

                <layout.FeedList data={item} />
              </li>
            );
          })}
        </ul>
      </div>

      <div className="bottom roboto">
        <div className="inner">
          {dateList?.map((item, index) => {
            return (
              <div className="dateList" key={"dateList" + index}>
                <p className="title">{item.title}</p>

                <div className="dateWrap">
                  <p className="date">{item.date || "YYYY/MM/DD"}</p>
                  <p className="weight">{item.weight || "--"}kg</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="adArea"></div>
    </Styles.Container>
  );
}

export default ComparisonSelectPage;
