import { useEffect, useRef, useState } from "react";
import utils from "utils";


export default function useInfiniteScroll({
  query,
}) {

  const isLoading = useRef(false);
  useEffect(() => {

    const tag = document.getElementsByTagName("main")[0];
    const listBody = document.getElementById("main_scroll_list");

    const handleScroll = () => {
      const { scrollTop, offsetHeight, clientHeight } = tag;
      if (query.status == 'success' && isLoading.current == false && listBody.offsetHeight < scrollTop + offsetHeight + 500) {
        if (query.hasNextPage){ 
          query.fetchNextPage();
          isLoading.current = true;
        }
        else if (!query.hasNextPage) return;
      }
    }
    tag.addEventListener('scroll', handleScroll)

    if(query.isFetching == false && query.hasNextPage){
      isLoading.current = false;
    }

    return () => tag.removeEventListener('scroll', handleScroll)
  }, [query])


  return {
   
  };
}
