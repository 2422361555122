import date from './date';
import etc from './etc';

export {
    date,
    etc
}

export default {
    date,
    etc
}