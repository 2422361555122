import Styles from "./styles";
import { ui } from "components";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth, useLanguage, useModals } from "hooks";

function AccountSettingPage() {
  const authInfo = useAuth();
  const modalOption = useModals();
  const navigate = useNavigate();
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix == "";

  const list = [
    {
      text: languageQuarter ? "로그아웃" : "Log out",
      onclick: () => {
        authInfo.logout();
        navigate("/login");
      },
    },
    {
      text: languageQuarter ? "회원탈퇴" : "Withdrawal of membership",
      onclick: () => {
        modalOption.setAccountDeleteModal((e) => {
          e.show = true;

          return { ...e };
        });
      },
    },
  ];

  useEffect(() => {
    if (!authInfo.getUserInfo.isLoading &&
      !authInfo.getUserInfo.data
    ) {
      navigate("/login")
    }
  }, [authInfo.getUserInfo]);

  return (
    authInfo.getUserInfo.data &&
    <Styles.Container>
      <div className="inner">
        <ui.button.PrevButton text={languageQuarter ? "계정 설정" : "Account Settings"} />

        <ul className="listWrap">
          {list.map((item, index) => {
            return (
              <li className="list" key={"list" + index}>
                <button type="button" onClick={item.onclick}>
                  {item.text}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </Styles.Container>
  );
}

export default AccountSettingPage;
