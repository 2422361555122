import connector from "service/connector"

const getDetail = () => {
  return {
    title : "test"
  }
}

const getList = (params) => {
  return [
    {title:"a1"},
    {title:"a2"},
    {title:"a3"}
  ];
}

const getProduct = async () => {
  return await connector.connectFetchController("product", "GET");
}

export {
  getDetail,
  getList,
  getProduct,
};

export default {
  getDetail,
  getList,
  getProduct,
}
