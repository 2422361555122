import Styles from "./styles";
import utils from "utils";
import { ui } from "components";
import { useLanguage, useOrder } from "hooks";

function PaymentDetailPage() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix == "";
  const orderInfo = useOrder({
    order: {
      category_info_id: 1,
      page: 1,
      recordSize: 999,
    },
  });

  const list = orderInfo.orderQuery.data?.list || [];


  return (
    <Styles.Container>
      <div className="inner">
        <ui.button.PrevButton text={languageQuarter ? "결제내역" : "Payment Details"} />

        <ul className="listWrap">
          {list?.length > 0 ? (
            <>
              {list?.map((item, index) => {
                return (
                  <li className="list" key={"list" + index}>
                    <p className="top">
                      <b>{item.type == 2
                        ? languageQuarter ? "연간" : "Annual"
                        : languageQuarter ? "월간" : "Monthly"
                      }</b>
                      {languageQuarter ? "프리미엄 서비스" : "Premium"}
                      <span className="price">
                        {languageQuarter
                          ? `${utils.etc.numberWithCommas(item.total_price)}원`
                          : `$ ${utils.etc.numberWithCommas(item.total_price)}`
                        }
                      </span>
                    </p>

                    <p className="date">
                      {utils.date.getDateType(item.created_at, "/")} -{" "}
                      {utils.date.getDateType(item.expired_at, "/")}
                    </p>

                    {new Date(
                      utils.date.getDateType(item.expired_at, "/")
                    ).getTime() > new Date().getTime() && (
                      <div className="now">{languageQuarter ? "현재 이용중" : "Currently in use"}</div>
                    )}
                  </li>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </ul>
      </div>
    </Styles.Container>
  );
}

export default PaymentDetailPage;
