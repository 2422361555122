import styled from "styled-components";

const Container = styled.div`
  margin-top: calc(100vw * (20 / 390));
  padding-bottom: calc(100vw * (63 / 390));

  & .periodTab {
    display: flex;
    margin-bottom: calc(100vw * (30 / 390));
    border: calc(100vw * (1 / 390)) solid var(--black50);
    border-radius: calc(100vw * (5 / 390));

    & .periodTabList {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% / 3);
      height: calc(100vw * (40 / 390));
      font-weight: 300;
      color: var(--black50);

      & span {
        position: relative;
        z-index: 1;
      }

      &.on {
        font-weight: 600;
        color: #fff;

        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          background-color: var(--black130);
          border-radius: calc(100vw * (5 / 390));
          content: "";
        }
      }
    }
  }

  & .dateWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: calc(100vw * (20 / 390));
    padding: 0 calc(100vw * (23 / 390));
    font-weight: 600;

    & button {
      display: block;
      width: calc(100vw * (15 / 390));
      height: calc(100vw * (15 / 390));
    }
  }

  & .bodyChangeWrap {
    display: flex;
    gap: calc(100vw * (9 / 390));
    margin-bottom: calc(100vw * (9 / 390));

    & .bodyChange {
      flex: 1 0;
      padding: calc(100vw * (12 / 390));
      border: calc(100vw * (1 / 390)) solid #000;
      border-radius: calc(100vw * (5 / 390));

      & .title {
        margin-bottom: calc(100vw * (15 / 390));
        font-weight: 600;
      }

      & .detail {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        font-weight: 300;

        & b {
          font-size: calc(100vw * (25 / 390));
          font-weight: 600;
        }

        & .weight {
          & b {
            margin-right: calc(100vw * (5 / 390));
          }
        }

        & .weightChange {
          display: flex;
          align-items: center;
          gap: calc(100vw * (3 / 390));
          font-size: calc(100vw * (12 / 390));

          & .icon {
            width: calc(100vw * (7 / 390));
            height: auto;
          }
        }
      }
    }
  }

  & .totalWrap {
    display: flex;
    gap: calc(100vw * (9 / 390));

    & .total {
      flex: 1 0;
      padding: calc(100vw * (12 / 390));
      border: calc(100vw * (1 / 390)) solid #000;
      border-radius: calc(100vw * (5 / 390));

      & .title {
        margin-bottom: calc(100vw * (20 / 390));
        font-weight: 600;
      }

      & .detail {
        display: flex;
        align-items: center;
        font-weight: 300;

        & span {
          margin-right: calc(100vw * (10 / 390));
        }

        & b {
          margin-right: calc(100vw * (3 / 390));
          padding-bottom: calc(100vw * (3 / 390));
          font-size: calc(100vw * (25 / 390));
          font-weight: 600;

          &:not(:first-of-type) {
            margin-left: calc(100vw * (3 / 390));
          }
        }
      }
    }
  }
`;

export { Container };

export default { Container };
