import Styles from "./styles";
import ui from "components/ui";
import { useState } from "react";
import { useLanguage } from "hooks";

function FindOutModal({ modalOption, modalClose }) {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix == "";
  
  const [inputs, setInputs] = useState({ etc: "" });

  const data = {
    id: "etc",
    name: "etc",
    value: inputs.etc,
    placeholder: languageQuarter
      ? "기타 경로 혹은 코드 입력해주세요"
      : "Please enter an alternate path or code.",
  };

  return (
    <Styles.Container>
      <div className="inner">
        {languageQuarter
          ? <div className="title">
            STAMFIT을 <br />
            어떻게 알게 되었나요?
          </div>
          : <div className="title">
            How did you <br />
            find out about ‘STAMFIT’?
          </div>
        }

        <div className="inputInner">
          <ui.input.BasicInput
            data={data}
            setInputs={setInputs}
            exportButtonEvent={() => {
              modalOption.setInputs(inputs)
              modalClose();
            }}
          />
        </div>
      </div>
    </Styles.Container>
  );
}

export { FindOutModal };

export default FindOutModal;
