import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  z-index: 11;

  & .modalInner {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: calc(100vw * (60 / 390)) 0;
    border-top-left-radius: calc(100vw * (20 / 390));
    border-top-right-radius: calc(100vw * (20 / 390));
    background-color: var(--black130);

    & .closeButton {
      position: absolute;
      top: calc(100vw * (15 / 390));
      left: 50%;
      transform: translateX(-50%);
      width: calc(100vw * (45 / 390));
      height: calc(100vw * (5 / 390));
      background-color: #fff;
      border-radius: calc(100vw * (100 / 390));
    }

    & .optionListWrap {
      padding: calc(100vw * (26 / 390)) calc(100vw * (20 / 390));
      background-color: var(--black120);
      border-radius: calc(100vw * (10 / 390));

      & .optionList {
        display: flex;
        align-items: center;
        gap: calc(100vw * (20 / 390));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (40 / 390));
        }

        & .icon {
          width: calc(100vw * (26 / 390));
          height: calc(100vw * (26 / 390));
        }

        & .text {
          font-weight: 300;
          color: var(--black30);
        }
      }
    }
  }

  & .overlay {
    width: 100%;
    height: 100%;
    background-color: transparent;
  }
`;

export { Container };

export default { Container };
