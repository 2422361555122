import Styles from "./styles";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ImgSplash from "resources/image/img_splash.png";

function IntroPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = 3000;

    setTimeout(() => {
      navigate("/login");
    }, timer);
  }, []);

  return (
    <Styles.Container>
      <img src={ImgSplash} alt="ImgSplash" />
    </Styles.Container>
  );
}

export default IntroPage;
