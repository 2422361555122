import Styles from "./styles";
import { DOMAIN } from "service/connector";
import { callNative, getCookie } from "utils/etc";
import { layout, common } from "components";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth, useBoard, useLanguage, useModals } from "hooks";

// img
import IconGraph from "resources/image/icon/icon_graph.svg";
import IconAdd from "resources/image/icon/icon_add.svg";
import { AdContainer } from "components/layout";

function AdsMainPage() {
  const navigate = useNavigate();
  const modalOption = useModals();
  const authInfo = useAuth();
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix == "";

  const [serchParams] = useSearchParams();
  const [tabIndex, setTabIndex] = useState(0);

  const boardInfo = useBoard({
    board: {
      category_info_id: tabIndex || "",
      page: 1,
      recordSize: 30,
    },
  });

  useEffect(() => {
    if (serchParams.get("token")) {
      document.cookie = `jwt=${serchParams.get(
        "token"
      )};path=/;domain=${DOMAIN}`;
    }
  }, []);

  useEffect(() => {
    init();
  }, [authInfo.getUserInfo]);

  const koList = [
    { text: "전체" },
    { text: "눈바디" },
    { text: "식사" },
    { text: "운동" },
    { text: "기타" },
  ]
  const tabList = languageQuarter
    ? koList
    : [
        { text: "All" },
        { text: "Body" },
        { text: "Meal" },
        { text: "Workout" },
        { text: "Etc" },
      ];

  const data = boardInfo.getBoard?.data?.list || [];

  const init = async () => {
    if (
      authInfo.getUserInfo.data &&
      authInfo.getUserInfo.data?.friend_check_yn == "Y"
    ) {
      modalOption.setFriendModal((e) => {
        e.show = true;
        return { ...e };
      });
    }
  };

  useEffect(() => {
    let countdown = null;
    if (authInfo.getUserInfo && countdown == null) {
      countdown = setInterval(() => {
        if (
          authInfo.getUserInfo.data &&
          authInfo.getUserInfo.data?.share_yn != "N"
        ) {
          clearInterval(countdown);
          return;
        }

        if (
          // sessionStorage.getItem("popup_yn") == "Y" &&
          authInfo.getUserInfo.data &&
          authInfo.getUserInfo.data?.share_yn == "N"
        ) {
          modalOption.setShareModal((e) => {
            e.show = true;
            return { ...e };
          });
          clearInterval(countdown);
        }
      }, 4000);
    }
    return () => {
      if (countdown) clearInterval(countdown);
    };
  }, [authInfo.getUserInfo]);

  return (
    <Styles.Container>
      <header>
        <div className="inner">
          <p className="nowPage">{languageQuarter ? "피드" : "Home"}</p>

          <button
            type="button"
            onClick={() => {
              if (authInfo.getUserInfo.data?.pay_yn == "Y") {
                navigate("/comparison/comparison");
              } else {
                modalOption.setSubscriptionModal((e) => {
                  e.show = true;
                  return { ...e };
                });
              }
            }}
          >
            <img src={IconGraph} alt="IconGraph" />
          </button>
        </div>
      </header>

      <ul className="tab">
        {tabList.map((item, index) => {
          return (
            <li
              className={"tabList" + (tabIndex === index ? " on" : "")}
              key={"tabList" + index}
              onClick={() => {
                setTabIndex(index);
              }}
            >
              <span>{item.text}</span>
            </li>
          );
        })}
      </ul>

      <main>
        {data?.length > 0 ? (
          <ul className="feeListWrap">
            {data?.map((item, index) => {
              return (
                <li
                  className="feedList"
                  key={"feedList" + index}
                  onClick={() => {
                    navigate("/feed?id=" + item.id, { state: {
                      id: item.id,
                      category: tabIndex,
                    }});
                  }}
                >
                  <layout.FeedList data={item} />
                </li>
              );
            })}
          </ul>
        ) : (
          <div className="addButton">
            <button
              type="button"
              onClick={() => {
                callNative("moveToPage", "Stamp", koList[tabIndex || 1].text);
              }}
            >
              <img src={IconAdd} alt="IconAdd" />
            </button>
          </div>
        )}
      </main>

      <common.NavigationBar />
      <AdContainer/>
    </Styles.Container>
  );
}

export default AdsMainPage;
