import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  bottom: calc(100vw * (65 / 390));
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100vw * (280 / 390));
  height: calc(100vw * (35 / 390));
  background-color: #fff;
  border: calc(100vw * (1 / 390)) solid var(--black130);
  border-radius: calc(100vw * (5 / 390));
  z-index: 11;
`;

export { Container };

export default { Container };
