import { useBoard, useLanguage } from "hooks";
import Styles from "./styles";
import ui from "components/ui";

function FeedDeleteModal({ modalClose, modalOption }) {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix == "";
  const boardInfo = useBoard({});
  const deleteBoard = () => {
    boardInfo.deleteBoard({
      id: modalOption.id
    })
    modalClose();
  }

  return (
    <Styles.Container>
      <div className="modalInner">
        <div className="top">
          <p className="title">
            {languageQuarter ? "삭제하기" : "Delete"}</p>
          
          {languageQuarter
            ? <p className="text">
              사진을 삭제할 경우, <br />
              복구가 불가능해요
            </p>
            : <p className="text">
              Once you delete the photo, <br />
              it cannot be recovered.
            </p>
          }
        </div>

        <div className="bottom">
          <p className="guideText">
            {languageQuarter
              ? "정말로 삭제하시겠어요?"
              : "Are you sure you want to delete?"
            }</p>

          <div className="buttonWrap">
            <ui.button.BasicButton
              buttonText={languageQuarter ? "취소" : "Cancel"}
              colorType={"black40"}
              sizeType={"height50"}
              onClick={modalClose}
            />
            <ui.button.BasicButton
              buttonText={languageQuarter ? "삭제하기" : "Delete"}
              sizeType={"height50"}
              onClick={deleteBoard}
            />
          </div>
        </div>
      </div>

      <div className="overlay"></div>
    </Styles.Container>
  );
}

export { FeedDeleteModal };

export default FeedDeleteModal;
