import { useState } from "react";
import { useQuery } from "react-query";
import { api } from "service";

export default function useSummary({ summary, compare }) {
  const [update, setUpdate] = useState();

  useQuery(
    ["summary", summary],
    () => {
      return api.summary.getSummary(summary);
    },
    {
      refetchOnWindowFocus: true,
      enabled: !!summary,
      onSuccess: (data) => {
        setUpdate((e) => {
          return { ...e, summary: data };
        });
      },
    }
  );

  const compareSummary = useQuery(
    ["summaryCompare", compare],
    () => {
      return api.summary.getCompareSummary(compare);
    },
    {
      refetchOnWindowFocus: true,
      enabled: !!compare,
    }
  );

  return {
    update,
    compareSummary,
  };
}
