import styled from "styled-components";

// img
import IconCheckOff from "resources/image/icon/icon_check_off.svg";
import IconCheckOn from "resources/image/icon/icon_check_on.svg";

const Input = styled.input`
  display: none;

  &:checked + label .checkbox {
    background-image: url(${IconCheckOn});
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: calc(100vw * (10 / 390));
  font-weight: 300;
  border-radius: calc(100vw * (6 / 390));
  cursor: pointer;

  & .checkbox {
    display: inline-block;
    width: calc(100vw * (20 / 390));
    height: calc(100vw * (20 / 390));
    background: url(${IconCheckOff}) no-repeat center / cover;
  }
`;

export { Input, Label };

export default { Input, Label };
