import styled from "styled-components";

const Container = styled.main`
  & .inner {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: 100dvh;
    padding: calc(100vw * (69 / 390)) 0 calc(100vw * (24 / 390));
  }
`;

export { Container };

export default { Container };
