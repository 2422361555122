import Styles from "./styles";
import { useModals } from "hooks";
import { ui } from "components";
import { useEffect } from "react";

function PayCompletePage() {
  const modalOption = useModals();

  useEffect(() => {
    modalOption.clearModals();
    modalOption.setSubscriptionCompleteModal((e) => {
      e.show = true;
      return { ...e };
    });
  }, []);

  return <Styles.Container></Styles.Container>;
}

export default PayCompletePage;
