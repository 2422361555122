import Styles from "./styles";
import { useLanguage } from "hooks";
import { useEffect, useState } from "react";

// highchart
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Custom Events 모듈을 불러와서 Highcharts에 적용
import HighchartsCustomEvents from "highcharts-custom-events";

// Highcharts에 Custom Events 모듈 적용
HighchartsCustomEvents(Highcharts);

function LineChart({ data, summaryData, type }) {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix == "";
  const { weight } = data;
  const [selectIndex, setSelectIndex] = useState(0);

  const monthName = (month) => {
    if (month == 1) return "January";
    else if (month == 2) return "February";
    else if (month == 3) return "March";
    else if (month == 4) return "April";
    else if (month == 5) return "May";
    else if (month == 6) return "June";
    else if (month == 7) return "July";
    else if (month == 8) return "August";
    else if (month == 9) return "September";
    else if (month == 10) return "October";
    else if (month == 11) return "November";
    else if (month == 12) return "December";
  };

  const weekOrdinal = ["1st", "2nd", "3rd", "4th", "5th", "6th"];
  const weekName = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  let week = summaryData.weight?.list[selectIndex]?.week_label?.split(" ")[1];
  let date = summaryData.weight?.list[selectIndex]?.date
    ?.split(" ")[0]
    ?.split("-");
  let day = languageQuarter
    ? ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"]
    : weekName;

  const subtitleText =
    (type == "monthly"
      ? languageQuarter
        ? `<span>${parseInt(date && date[1])}월 ${week}주차 평균`
        : `<span>Average for the ${weekOrdinal[week - 1]} week of ${monthName(
            parseInt(date && date[1])
          )}`
      : languageQuarter
      ? `<span>${parseInt(date && date[1])}월 ${parseInt(date && date[2])}일 ${
          day[
            new Date(
              summaryData.weight?.list[selectIndex]?.date?.split(" ")[0]
            ).getDay()
          ]
        }`
      : `<span>${
          day[
            new Date(
              summaryData.weight?.list[selectIndex]?.date?.split(" ")[0]
            ).getDay()
          ]
        },
          ${monthName(parseInt(date && date[1]))} ${parseInt(
          date && date[2]
        )}`) + `</span> <br/> <b>${weight[selectIndex]}kg</b>`;

  function getMaxValue() {
    let maxValue = 0;
    weight.map((value) => {
      maxValue = Math.max(maxValue, value);
    });
    return maxValue;
  }

  const [options, setOptions] = useState({
    chart: {
      type: "line",
      backgroundColor: "#1D1D1D",
      style: {
        color: "#FFF",
      },
      height: 258,
      reflow: true,
      spacing: [4, 0, 11, 0],
      events: {
        load: function () {
          var chart = this,
            series = chart.series[0]?.data;

          series?.forEach(function (point) {
            if (point.y !== null) {
              chart.renderer
                .path([
                  "M",
                  point.plotX + chart.plotLeft,
                  chart.plotTop + chart.plotHeight,
                  "L",
                  point.plotX + chart.plotLeft,
                  point.plotY + chart.plotTop,
                ])
                .attr({
                  "stroke-width": 0.3,
                  stroke: "#FFF",
                  dashstyle: "LongDash",
                })
                .add();
            }
          });
        },
      },
    },
    title: {
      text: languageQuarter ? "체중 그래프" : "Weight Graph",
      align: "left",
      style: {
        fontFamily: "Pretendard Variable",
        fontSize: "14px",
        fontWeight: "600",
        color: "#FFF",
      },
    },
    subtitle: {
      text: subtitleText,
      align: "left",
      useHTML: true,
      y: 42,
      style: {
        fontFamily: "Pretendard Variable",
        fontSize: "12px",
        fontWeight: "300",
        color: "#FFF",
      },
    },
    xAxis: {
      categories: [],
      labels: {
        style: {
          fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: "300",
          color: "#FFF",
        },
      },
      lineWidth: 0,
    },
    yAxis: {
      // min: 0,
      // max: getMaxVa,
      title: {
        text: null,
      },
      labels: {
        enabled: false,
      },
      gridLineWidth: 0,
    },
    series: [],
    legend: {
      enabled: false,
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: false,
          color: "#FFF",
        },
        enableMouseTracking: true,
      },
      series: {
        cursor: "pointer",
        point: {
          events: {
            click: function (event) {
              setSelectIndex(this.index);
            },
          },
        },
      },
    },
    tooltip: {
      enabled: false, // 툴팁 비활성화
    },
    credits: {
      enabled: false,
    },
  });

  const refresh = () => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      series: [
        {
          name: "체중",
          data: weight.map((value) => (value == 0 ? 1 : value)) || [],
          color: "#FFF",
          marker: {
            enabled: false,
          },
        },
      ],
      subtitle: {
        ...prevOptions.subtitle,
        text: subtitleText,
      },
      xAxis: {
        ...prevOptions.xAxis,
        categories:
          type == "weekly" ? weekName.map((i) => i.slice(0, 2)) : weekOrdinal,
        labels: {
          enabled: true,
          events: {
            click: function (event) {
              const chart = event.target;
              const data =
                type == "weekly"
                  ? weekName.map((i) => i.slice(0, 2))
                  : weekOrdinal;
              data.map((value, index) => {
                if (chart.innerHTML == value) {
                  setSelectIndex(index);
                }
              });
            },
          },
        },
      },
      yAxis: {
        min: getMaxValue() == 0 ? 0 : null,
        max: getMaxValue() == 0 ? 10 : null,
        title: {
          text: null,
        },
        labels: {
          enabled: false,
        },
        gridLineWidth: 0,
      },
    }));
  };

  useEffect(() => {
    let targetIndex = 0;
    weight.map((value, index) => {
      if (value != 0 && targetIndex == 0) {
        targetIndex = index;
      }
    });
    setSelectIndex(targetIndex);
    refresh();
  }, [summaryData]);

  useEffect(() => {
    refresh();
  }, [data, selectIndex]);

  return (
    <Styles.Container>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Styles.Container>
  );
}

export default LineChart;
