import {
  FindOutModal,
  PushModal,
  ShareModal,
  FriendModal,
  MoreBottomSheetModal,
  ToastModal,
  FeedDeleteModal,
  SubscriptionModal,
  SubscriptionCompleteModal,
  AccountDeleteModal,
} from "components/modal";
import { useModals } from "hooks";
import { useEffect } from "react";

// img
export default function ModalPage() {
  const modalOption = useModals();

  useEffect(() => {
    const body = document.body;
    const html = document.querySelector("html");
    if (
      modalOption.findOutModal.show ||
      modalOption.pushModal.show ||
      modalOption.shareModal.show ||
      modalOption.friendModal.show ||
      modalOption.moreBottomSheetModal.show ||
      modalOption.feedDeleteModal.show ||
      modalOption.subscriptionModal.show ||
      modalOption.accountDeleteModal.show
    ) {
      body.className = "hidden";
      html.className = "hidden";
    } else {
      body.classList.remove("hidden");
      html.classList.remove("hidden");
    }
  }, [modalOption]);

  return (
    <>
      {
        //가입 경로 모달
        modalOption.findOutModal.show ? (
          <FindOutModal
            modalOption={modalOption.findOutModal}
            modalClose={() => {
              modalOption.setFindOutModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        //푸쉬 알림 허용 모달
        modalOption.pushModal.show ? (
          <PushModal
            modalOption={modalOption.pushModal}
            modalClose={() => {
              modalOption.setPushModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        //친구 공유 모달
        modalOption.shareModal.show ? (
          <ShareModal
            modalOption={modalOption.shareModal}
            modalClose={() => {
              modalOption.setShareModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        //친구 참여 모달
        modalOption.friendModal.show ? (
          <FriendModal
            modalOption={modalOption.friendModal}
            modalClose={() => {
              modalOption.setFriendModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        //피드 내 옵션 클릭 시 모달
        modalOption.moreBottomSheetModal.show ? (
          <MoreBottomSheetModal
            modalOption={modalOption.moreBottomSheetModal}
            modalClose={() => {
              modalOption.setMoreBottomSheetModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        //토스트 모달
        modalOption.toastModal.show ? (
          <ToastModal
            modalOption={modalOption.toastModal}
            modalClose={() => {
              modalOption.setToastModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        //피드 삭제 확인 모달
        modalOption.feedDeleteModal.show ? (
          <FeedDeleteModal
            modalOption={modalOption.feedDeleteModal}
            modalClose={() => {
              modalOption.setFeedDeleteModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        //구독 모달
        modalOption.subscriptionModal.show ? (
          <SubscriptionModal
            modalOption={modalOption.subscriptionModal}
            modalClose={() => {
              modalOption.setSubscriptionModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        //구독 완료 모달
        modalOption.subscriptionCompleteModal.show ? (
          <SubscriptionCompleteModal
            modalOption={modalOption.subscriptionCompleteModal}
            modalClose={() => {
              modalOption.setSubscriptionCompleteModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        // 회원 탈퇴 모달
        modalOption.accountDeleteModal.show ? (
          <AccountDeleteModal
            modalOption={modalOption.accountDeleteModal}
            modalClose={() => {
              modalOption.setAccountDeleteModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
    </>
  );
}
