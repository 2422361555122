import connector from "service/connector";
import utils from "utils";

const getBoard = async data => await connector.jsonConnector(
  "board" + (data ? "?" + utils.etc.getFilterStr(data) : ""), "GET"
);

const getDetailBoardList = async (data) => {
  let response =  await connector.authConnector(
    "board/detail-list" + (data ? "?" + utils.etc.getFilterStr(data) : ""),
    "GET"
  );
  let result = {
    list :  response?.list,
    page :  data.page,
    totalCount : response?.pagination?.totalRecordCountAll
  }
  return result;
};

const getGroup = async (data) => {
  return await connector.authConnector(
    "board/group" + (data ? "?" + utils.etc.getFilterStr(data) : ""),
    "GET"
  );
};
const postBoard = async (data) => {
  return await connector.authConnector("board", "POST", data);
};
const getBoardDetail = async (data) => {
  return await connector.authConnector("board/" + data, "GET");
};
const deleteBoard = async (data) => {
  return await connector.jsonConnector("board/del", "POST", data);
};
const postBoardUpdate = async (data) => {
  return await connector.connectFetchController("board/update", "POST" + data);
};

export {
  getBoard,
  getGroup,
  postBoard,
  getBoardDetail,
  getDetailBoardList,
  deleteBoard,
  postBoardUpdate,
};

export default {
  getBoard,
  getGroup,
  postBoard,
  getBoardDetail,
  getDetailBoardList,
  deleteBoard,
  postBoardUpdate,


  nextAlbum : getDetailBoardList.fetchNextPage,
  hasNextPage : getDetailBoardList.hasNextPage,
};
