import connector from "service/connector"
import utils from "utils";

const getSummary = async (data) => {
  return await connector.authConnector("summary" + (data ? "?" + utils.etc.getFilterStr(data) : ""), "GET");
}
const getCompareSummary = async (data) => {
  return await connector.authConnector("summary/board" + (data ? "?" + utils.etc.getFilterStr(data) : ""), "GET");
}

export {
  getSummary,
  getCompareSummary,
};

export default {
  getSummary,
  getCompareSummary,
}
