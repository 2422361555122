import connector from "service/connector";

// 소셜 로그인
const socialLogin = async (path) => {
  return await connector.connectFetchController(
    "social/login?type=" + path,
    "GET"
  );
};

// 소셜 회원가입
const socialSignUp = async (data) => {
  return await connector.authConnector("social/sign", "POST", data);
};

// 회원상세 정보
const getUserInfo = async () => {
  return await connector.authConnector("user/detail", "GET");
};

// 회원정보 변경
const userUpdate = async (data) => {
  return await connector.authConnector("user/update", "POST", data);
};
// 회원탈퇴
const resign = async (data) => {
  return await connector.jsonConnector("user/withdraw", "POST", data);
};

export { socialLogin, socialSignUp, getUserInfo, userUpdate, resign };

export default {
  socialLogin,
  socialSignUp,
  getUserInfo,
  userUpdate,
  resign,
};
