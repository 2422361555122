import styled from "styled-components";

// img
import IconArrowRight from "resources/image/icon/icon_arrow_right_white.svg";
import IconArrowRightBlack from "resources/image/icon/icon_arrow_right_black.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - calc(100vw * (129 / 390)));
  min-height: calc(100dvh - calc(100vw * (129 / 390)));
  margin-top: calc(100vw * (30 / 390));

  & .bodyContainer {
    display: flex;
    gap: calc(100vw * (8 / 390));
    margin-bottom: calc(100vw * (24 / 390));
    padding: calc(100vw * (40 / 390)) calc(100vw * (9 / 390))
      calc(100vw * (35 / 390));
    border-radius: calc(100vw * (10 / 390));
    background-color: var(--black130);

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    & .title {
      margin-bottom: calc(100vw * (26 / 390));
      font-weight: 600;
      color: #fff;
    }

    & .bodyWrap:first-child .title {
      position: relative;

      &::after {
        position: absolute;
        top: 50%;
        left: calc(100% + calc(100vw * (46 / 390)));
        transform: translateY(-50%);
        width: calc(100vw * (15 / 390));
        height: calc(100vw * (15 / 390));
        background: url(${IconArrowRight}) no-repeat center / cover;
        content: "";
      }
    }

    & .lineImgWrap {
      width: calc(100vw * (158 / 390));
      height: calc(100vw * (364 / 390));
    }
  }

  & .dateContainer {
    display: flex;
    gap: calc(100vw * (42 / 390));

    & .dateWrap {
      position: relative;
      width: calc(100vw * (150 / 390));
      padding: calc(100vw * (10 / 390)) calc(100vw * (26 / 390));
      text-align: center;
      border: calc(100vw * (1 / 390)) solid var(--black50);
      border-radius: calc(100vw * (5 / 390));
      cursor: pointer;

      &:first-child::after {
        position: absolute;
        top: calc(100vw * (23 / 390));
        left: calc(100% + calc(100vw * (13 / 390)));
        width: calc(100vw * (15 / 390));
        height: calc(100vw * (15 / 390));
        background: url(${IconArrowRightBlack}) no-repeat center / cover;
        content: "";
      }

      & .date {
        margin-bottom: calc(100vw * (10 / 390));
        font-weight: 300;
      }

      & .weight {
        font-weight: 600;
      }
    }
  }

  & .adArea {
    height: calc(100vw * (36 / 390));
    margin-top: auto;
    background-color: var(--black130);
  }
`;

export { Container };

export default { Container };
