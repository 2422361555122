import Styles from "./styles";
import ui from "components/ui";
import { useNavigate } from "react-router-dom";
import { useAuth, useLanguage } from "hooks";

// img
import IconDelete from "resources/image/icon/icon_delete_white.svg";
import { api } from "service";

function AccountDeleteModal({ modalClose }) {
  const authInfo = useAuth();
  const navigate = useNavigate();
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix == "";

  return (
    <Styles.Container>
      <div className="modalInner">
        <button type="button" className="closeButton" onClick={modalClose}>
          <img src={IconDelete} alt="IconDelete" />
        </button>

        <div className="top">
          <p className="title">{languageQuarter ? "회원탈퇴" : "Withdrawal of membership"}</p>
          {languageQuarter ?
            <p className="text">
              회원탈퇴 시 계정 정보 및 사진은 <br />
              삭제되어 복구가 불가해요.
            </p> : <p className="text">
              If you withdraw membership, <br />
              your datas cannot be recovered.
            </p>
          }
        </div>

        <div className="bottom">
          <p className="guideText">{languageQuarter
            ? "정말로 탈퇴하시겠어요?"
            : "Are you sure you want to withdraw?"
          }</p>

          <div className="buttonWrap">
            <ui.button.BasicButton
              buttonText={languageQuarter ? "떠날래요" : "Leave"}
              colorType={"black40"}
              sizeType={"height50"}
              onClick={() => {
                modalClose();
                authInfo.resign();
                navigate("/login");
              }}
            />
            <ui.button.BasicButton
              buttonText={languageQuarter ? "더 써볼래요" : "More"}
              sizeType={"height50"}
              onClick={modalClose}
            />
          </div>
        </div>
      </div>

      <div className="overlay"></div>
    </Styles.Container>
  );
}

export { AccountDeleteModal };

export default AccountDeleteModal;
