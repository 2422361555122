import Styles from "./styles";
import { useEffect } from "react";

function ToastModal({ modalOption, modalClose }) {
  useEffect(() => {
    setTimeout(() => {
      modalClose();
    }, 2000);
  }, []);

  return <Styles.Container>{modalOption.text}</Styles.Container>;
}

export { ToastModal };

export default ToastModal;
