import BasicInput from "./BasicInput";
import BasicCheckbox from "./BasicCheckbox";
import BasicRadio from "./BasicRadio";
import BasicSelect from "./BasicSelect";

export { BasicInput, BasicCheckbox, BasicRadio, BasicSelect };

export default {
  BasicInput,
  BasicCheckbox,
  BasicRadio,
  BasicSelect,
};
