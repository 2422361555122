import styled from "styled-components";

// img
import ImgSubscriptionComplete from "resources/image/img_subscription_complete.png";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  color: #fff;
  background-color: var(--black130);
  z-index: 11;

  &::after {
    position: absolute;
    bottom: calc(100vw * (151 / 390));
    left: calc(100vw * (-42 / 390));
    width: calc(100vw * (302 / 390));
    height: calc(100vw * (352 / 390));
    background: url(${ImgSubscriptionComplete}) no-repeat center / cover;
    content: "";
    z-index: -1;
  }

  & .closeButton {
    position: absolute;
    top: calc(100vw * (15 / 390));
    right: calc(100vw * (29 / 390));
    width: calc(100vw * (25 / 390));
    height: calc(100vw * (25 / 390));
  }

  & .inner {
    display: flex;
    flex-direction: column;
    height: 100vh;
    height: 100dvh;
    padding: calc(100vw * (71 / 390)) 0 calc(100vw * (50 / 390));
  }

  & .tag {
    width: fit-content;
    margin-bottom: calc(100vw * (40 / 390));
    padding: calc(100vw * (4 / 390)) calc(100vw * (21 / 390));
    font-weight: 300;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid #fff;
    border-radius: calc(100vw * (23 / 390));
  }

  & .title {
    margin-bottom: calc(100vw * (11 / 390));
    font-size: calc(100vw * (30 / 390));
    font-weight: 900;
  }

  & .text {
    font-size: calc(100vw * (25 / 390));
    font-weight: 600;
  }

  & .nextButton {
    margin-top: auto;
  }
`;

export { Container };

export default { Container };
