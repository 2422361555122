import Styles from "./styles";
import { useState, useRef, useEffect } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

function BasicSelect({ optionList, placeholder, select, setSelect }) {
  const selectRef = useRef(null);

  const [isOptionOpen, setIsOptionOpen] = useState(false);

  useEffect(() => {
    // 특정 영역 외 클릭 시 발생하는 이벤트
    function handleFocus(e) {
      if (selectRef.current && !selectRef.current.contains(e.target)) {
        setIsOptionOpen(false);
      }
    }

    // 이벤트 리스너에 handleFocus 함수 등록
    document.addEventListener("mouseup", handleFocus);
    return () => {
      document.removeEventListener("mouseup", handleFocus);
    };
  }, [selectRef]);

  return (
    <Styles.Container ref={selectRef}>
      <div
        className={
          "nowSelect" + 
          (select !== "" ? " on" : "") + 
          (isOptionOpen ? " focus" : "")
        }
        onClick={() => {
          setIsOptionOpen(!isOptionOpen);
        }}
      >
        {select !== "" ? select : placeholder}
      </div>

      {isOptionOpen && (
        <OverlayScrollbarsComponent
          className="menuContainer"
          options={{ scrollbars: { autoHide: "scroll" } }}
        >
          <ul className="optionListWrap">
            {optionList?.map((item, index) => {
              return (
                <li
                  className="optionList"
                  key={"optionList" + index}
                  onClick={() => {
                    setSelect(item.text);
                    setIsOptionOpen(false);
                  }}
                >
                  {item.text}
                </li>
              );
            })}
          </ul>
        </OverlayScrollbarsComponent>
      )}
    </Styles.Container>
  );
}

export default BasicSelect;
