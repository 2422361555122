import styled from "styled-components";

const Container = styled.main`
  padding-top: calc(100vw * (65 / 390));

  & .tab {
    display: flex;
    gap: calc(100vw * (10 / 390));

    & .tabList {
      display: flex;
      align-items: center;
      height: calc(100vw * (34 / 390));
      padding: 0 calc(100vw * (14 / 390));
      font-weight: 300;
      color: var(--black70);
      border-radius: calc(100vw * (100 / 390));
      border: calc(100vw * (1 / 390)) solid var(--black70);
      cursor: pointer;

      &.on {
        font-weight: 600;
        color: #fff;
        background-color: var(--black130);
        border-color: var(--black130);
      }
    }
  }
`;

export { Container };

export default { Container };
