import styled from "styled-components";

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--black110);
  background-color: var(--black130);
  box-shadow: 0 calc(100vw * (-2 / 390)) calc(100vw * (4 / 390)) 0
    rgba(0, 0, 0, 0.1);
  z-index: 6;

  & .home,
  .mypage {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 calc(100vw * (61 / 390));
    height: calc(100vw * (50 / 390));

    & .icon {
      display: inline-block;
      width: calc(100vw * (21 / 390));
      height: calc(100vw * (21 / 390));
      filter: var(--black100-filter);
    }

    &.on .icon {
      filter: var(--white-filter);
    }
  }

  & .stamp {
    position: absolute;
    top: calc(100vw * (-30 / 390));
    left: 50%;
    transform: translateX(-50%);
    width: calc(100vw * (65 / 390));
    height: calc(100vw * (65 / 390));
  }

  & .stampListWrap {
    position: absolute;
    bottom: calc(100% + calc(100vw * (58 / 390)));
    left: 50%;
    transform: translateX(-50%);
    width: calc(100vw * (342 / 390));
    padding: calc(100vw * (26.5 / 390)) calc(100vw * (20 / 390));
    background-color: var(--black130);
    border: calc(100vw * (0.5 / 390)) solid var(--black70);
    border-radius: calc(100vw * (10 / 390));

    & .stampList {
      display: flex;
      align-items: center;
      gap: calc(100vw * (20 / 390));
      font-weight: 300;
      color: #fff;

      &:not(:last-child) {
        margin-bottom: calc(100vw * (40 / 390));
      }

      & .icon {
        width: calc(100vw * (26 / 390));
        height: calc(100vw * (26 / 390));
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
