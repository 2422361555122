import styled from "styled-components";

// img
import BgSignupComplete from "resources/image/bg_signup_complete.png";

const Container = styled.main`
  background: url(${BgSignupComplete}) no-repeat center / cover;

  & .inner {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: 100dvh;
    padding: calc(100vw * (109 / 390)) 0 calc(100vw * (50 / 390));
    text-align: center;
  }

  & .completeText {
    margin-bottom: calc(100vw * (15 / 390));
    font-size: calc(100vw * (20 / 390));
    font-weight: 300;
  }

  & .goalText {
    font-size: calc(100vw * (25 / 390));
    font-weight: 600;
  }

  & .startButton {
    margin-top: auto;
  }
`;

export { Container };

export default { Container };
