import React, { useEffect } from "react";
import { Route, Routes, BrowserRouter, useLocation } from "react-router-dom";
import Pages from "pages";
import utils from "utils";
import Modal from "components/modal";

export default function CustomRoute() {
  let location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTo(0, 0);

    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "ISBACK",
          isBack:
            location.pathname == "" || location.pathname == "/"
              ? "true"
              : false,
        })
      );
    }

    if (
      window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage &&
      utils.etc.getCookie("jwt")
    ) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: "LOGIN", token: utils.etc.getCookie("jwt") })
      );
    }

  }, [location]);
  
  return (
    <Routes>
      {/* 피드 */}
      <Route path="/ads" element={<Pages.AdsMainPage />} />
      <Route path="/" element={<Pages.MainPage />} />
      <Route path="/sign/success" element={<Pages.NativePage />} />
      <Route path="/native" element={<Pages.NativePage />} />
      <Route path="/feed" element={<Pages.FeedDetailPage />} />
      {/* 온보딩 */}
      <Route path="/intro" element={<Pages.IntroPage />} />
      <Route path="/login" element={<Pages.LoginPage />} />
      <Route path="/ask" element={<Pages.AskPage />} />
      <Route path="/terms/:id" element={<Pages.TermsPage />} />
      <Route path="/signup/stepA" element={<Pages.SignupPage />} />
      <Route path="/find-out" element={<Pages.FindOutPage />} />
      <Route path="/signup/complete" element={<Pages.SignupCompletePage />} />
      <Route path="/pay/complete" element={<Pages.PayCompletePage />} />
      {/* 분석 */}
      <Route path="/comparison/:id" element={<Pages.ComparisonPage />} />
      <Route
        path="/comparison-select"
        element={<Pages.ComparisonSelectPage />}
      />
      {/* 마이페이지 */}
      <Route path="/mypage" element={<Pages.MyPage />} />
      <Route
        path="/mypage/payment/detail"
        element={<Pages.PaymentDetailPage />}
      />
      <Route
        path="/mypage/account-setting"
        element={<Pages.AccountSettingPage />}
      />

      <Route
        path="/app/download"
        element={<Pages.AppDownload />}
      />

      <Route
        path="/pay/transaction"
        element={<Modal.SubscriptionModal />}
      />


    </Routes>
  );
}
