import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  z-index: 11;

  & .modalInner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100vw * (342 / 390));
    text-align: center;
    border-radius: calc(100vw * (15 / 390));
    overflow: hidden;

    & .closeButton {
      position: absolute;
      top: calc(100vw * (15 / 390));
      right: calc(100vw * (15 / 390));
      width: calc(100vw * (25 / 390));
      height: calc(100vw * (25 / 390));
      z-index: 1;
    }

    & .top {
      position: relative;
      padding: calc(100vw * (56 / 390)) 0 calc(100vw * (39 / 390));
      color: #fff;
      background-color: var(--black130);

      & .title {
        margin: 0 auto calc(100vw * (8 / 390));
        font-weight: 300;
      }

      & .text {
        font-size: calc(100vw * (25 / 390));
        font-weight: 600;
      }

      & .tag {
        position: absolute;
        bottom: calc(100vw * (-20 / 390));
        left: 50%;
        transform: translateX(-50%);
        padding: calc(100vw * (6 / 390)) calc(100vw * (21 / 390));
        font-size: calc(100vw * (20 / 390));
        font-weight: 600;
        background-color: var(--black130);
        border: 1px solid #fff;
        border-radius: calc(100vw * (100 / 390));
      }
    }

    & .bottom {
      padding: calc(100vw * (35 / 390)) calc(100vw * (27 / 390))
        calc(100vw * (20 / 390));
      background-color: #fff;

      & .benefitListWrap {
        display: flex;
        justify-content: center;
        gap: calc(100vw * (3 / 390));
        margin-bottom: calc(100vw * (12 / 390));

        & .benefitList {
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc(100vw * (68 / 390));
          height: calc(100vw * (80 / 390));
          font-size: calc(100vw * (14 / 390));
          font-weight: 600;
          color: #fff;
          white-space: pre;
          background-color: var(--black130);
          border-radius: calc(100vw * (10 / 390));
        }
      }

      & .guideText {
        margin-bottom: calc(100vw * (15 / 390));
        font-size: calc(100vw * (14 / 390));
        font-weight: 300;
      }
    }
  }

  & .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(9, 9, 9, 0.8);
  }
`;

export { Container };

export default { Container };
