import { api } from "service";
import { useState } from "react";
import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import { useLanguage, useModals } from "hooks";
import utils from "utils";

export default function useBoard({ board, group, detail, boardDetailList }) {
  const modalOption = useModals();
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix == "";
  const queryClient = useQueryClient();

  const getBoard = useQuery(["boardList", board], () =>
    api.board.getBoard(board),
  { refetchOnWindowFocus: true, enabled: !!board });

  const getDetailBoardList = useInfiniteQuery(
    ["boardDetailList", boardDetailList],
    ({pageParam = 1})=>{ 
      return api.board.getDetailBoardList({...boardDetailList, page : pageParam });
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!boardDetailList,
      getNextPageParam: (lastPage) => {
        return lastPage?.list?.length == 0 ? false :  lastPage?.page + 1;
      },
    }
  );

  const getGroup = useQuery(
    ["boardGroupList", group],
    () => {
      return api.board.getGroup(group);
    },
    {
      refetchOnWindowFocus: true,
      enabled: !!group,
    }
  );

  const getBoardDetail = useQuery(
    ["boardDetail", detail],
    () => {
      return api.board.getBoardDetail(detail);
    },
    {
      refetchOnWindowFocus: true,
      enabled: !!detail,
    }
  );



  const deleteQueryCache = (id, keyList) => {
    const allQueries = queryClient.getQueryCache().findAll();
    const keyObject = {};
    keyList.map((value)=>{ keyObject[value] = true; })
    let list = allQueries.filter(query => keyObject[query.queryKey[0]]).map((value)=>{
      let data = queryClient.getQueryData(value.queryKey);
      if(data && data.pages && data.pageParams){
        data.pages = data.pages.map((pages) => {
          pages.list = pages.list.filter((item)=> {
            return item.id != id;
          }).map((v) => { console.log(v); return v;});
          return pages;
        })
      }
      queryClient.setQueriesData(value.queryKey, data);
    });

    return list;
  }

  const deleteBoard = async (id) => {
    const result = await api.board.deleteBoard(JSON.stringify(id));
    if (result.success) {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = languageQuarter
          ? "삭제가 완료되었습니다."
          : "Deletion has been completed."
        return { ...e };
      });

      deleteQueryCache(id?.id || id,["boardDetailList", "boardList"]);
      if(utils.etc.getSearchParam().id == id?.id){
        // window.location.replace("/feed");
      }
    }
  };

  return {
    getGroup,
    getBoard,
    getBoardDetail,
    deleteBoard,
    getDetailBoardList,
  };
}
