import Styles from "./styles";
import { ui } from "components";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuth, useLanguage, useModals } from "hooks";

function FindOutPage() {
  const authInfo = useAuth();
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix == "";

  const modalOption = useModals();
  const location = useLocation();

  const [findOut, setfindOut] = useState("");
  const [inputs, setInputs] = useState({ etc: "" });
  const [isDisabled, setIsDisabled] = useState(true);

  const findOutList = [
    {
      id: "instagram",
      labelText: languageQuarter ? "인스타그램" : "Instargram",
    },
    {
      id: "youtube",
      labelText: languageQuarter ? "유튜브" : "Youtube",
    },
    {
      id: "blog",
      labelText: languageQuarter ? "블로그" : "Blog",
    },
    {
      id: "facebook",
      labelText: languageQuarter ? "페이스북" : "Facebook",
    },
    {
      id: "tiktok",
      labelText: languageQuarter ? "틱톡" : "Tictok",
    },
    {
      id: "acquaintance",
      labelText: languageQuarter ? "일상 지인 추천" : "Recommendation by a friend",
    },
    {
      id: "gymAcquaintance",
      labelText: languageQuarter ? "헬스장 지인 추천" : "Recommendation by a gym friend",
    },
    {
      id: "etc",
      labelText: languageQuarter ? "기타" : "Etc",
    },
  ];

  const data = {
    id: "etc",
    name: "etc",
    value: inputs.etc,
    placeholder: languageQuarter
      ? "기타 경로 혹은 코드 입력해주세요"
      : "Please enter an alternate path or code.",
  };

  useEffect(() => {
    if (findOut !== "" && findOut !== "etc") {
      setIsDisabled(false);
    } else if (findOut === "etc" && inputs.etc !== "") {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [findOut, inputs]);

  const onNext = () => {
    authInfo.socialSignUp({
      ...location.state,
      register_from:
        findOut === "etc"
          ? inputs.etc
          : findOutList.filter((v) => v.id == findOut)[0].labelText,
    });
  };

  return (
    <Styles.Container>
      <div className="inner">
        {languageQuarter
          ? <h1>
            STAMFIT을 <br />
            어떻게 알게 되었나요?
          </h1>
          : <h1>
            How did you <br />
            find out about ‘STAMFIT’?
          </h1>
        }

        <ul className="inputListWrap">
          {findOutList?.map((item, index) => {
            return (
              <li className="inputList" key={"findOutList" + index}>
                <ui.input.BasicRadio
                  data={item}
                  name={"findOut"}
                  check={findOut}
                  setCheck={setfindOut}
                />
              </li>
            );
          })}
        </ul>

        {findOut === "etc" && (
          <div
            className="etcReason"
            onClick={() => {
              modalOption.setFindOutModal((e) => {
                e.show = true;
                e.setInputs = setInputs;

                return { ...e };
              });
            }}
          >
            <ui.input.BasicInput data={data} setInputs={setInputs} readOnly />
          </div>
        )}

        <div className="nextButton">
          <ui.button.BasicButton
            buttonText={languageQuarter ? "다음" : "Next"}
            disabled={isDisabled}
            onClick={onNext}
          />
        </div>
      </div>
    </Styles.Container>
  );
}

export default FindOutPage;
