import styled from "styled-components";

const Container = styled.main`
  & .inner {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: 100dvh;
    padding: calc(100vw * (85 / 390)) 0 calc(100vw * (50 / 390));
  }

  & .askListWrap {
    margin-bottom: calc(100vw * (42 / 390));

    & .askList {
      &:not(:last-child) {
        margin-bottom: calc(100vw * (30 / 390));
      }

      & label {
        display: block;
        margin-bottom: calc(100vw * (10 / 390));
        font-size: calc(100vw * (20 / 390));
        font-weight: 600;
      }

      & .textarea {
        position: relative;

        & textarea {
          display: block;
          width: 100%;
          height: calc(100vw * (234 / 390));
          padding: calc(100vw * (20 / 390)) calc(100vw * (25 / 390))
            calc(100vw * (39 / 390));
          background-color: var(--black20);
          border-radius: calc(100vw * (5 / 390));

          &::placeholder {
            color: var(--black50);
          }
        }

        & .textNumber {
          position: absolute;
          bottom: calc(100vw * (11 / 390));
          right: calc(100vw * (19 / 390));
          font-size: calc(100vw * (12 / 390));
          font-weight: 300;
          color: var(--black80);
        }
      }
    }
  }

  & .termsListWrap {
    margin-bottom: calc(100vw * (27 / 390));

    & .termsList {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: calc(100vw * (10 / 390));
      }

      & .detailTermsButton {
        font-size: calc(100vw * (12 / 390));
        font-weight: 300;
        color: var(--black80);
        text-decoration: underline;
        text-underline-offset: calc(100vw * (2 / 390));
      }
    }
  }

  & .askButton {
    margin-top: auto;
  }
`;

export { Container };

export default { Container };
