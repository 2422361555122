import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  width: 100dvw;
  height: 100vh;
  height: 100dvh;
  background-color: #fff;
  z-index: 11;

  & .inner {
    padding: calc(100vw * (50 / 390)) 0;
  }

  & .title {
    margin-bottom: calc(100vw * (126 / 390));
    font-size: calc(100vw * (25 / 390));
    font-weight: 600;
  }

  & .inputInner {
    width: calc(100vw * (333 / 390));
    margin: 0 auto;
  }
`;

export { Container };

export default { Container };
