import Styles from "./styles";
import {date} from "utils";
import {IMAGEPATH} from "service/connector";
import {useLocation} from "react-router-dom";
import {ProgressiveImage} from "components/common";

function FeedList({ data, big, captureRef}) {
  const {
    stamp,
    tall,
    image,
    thumbnail,
    created_at,
    category_info_id,
    weight,
    total_calories,
    eat_range,
    logo_yn,
    large_yn,
  } = data;

  const location = useLocation();

  const renderDetail = (type) => {
    switch (parseInt(type)) {
      case 1:
        return (
          weight && (
            <div className="weight">
              {weight}
              <span>kg</span>
            </div>
          )
        );
      case 2:
        return (
          <div className="kcal">
            +{Math.round(total_calories * (eat_range / 100))}
            <span>kcal</span>
          </div>
        );
      case 3:
        return (
          <div className="kcal">
            -{total_calories || 0}
            <span>kcal</span>
          </div>
        );
    }
  };
  var w = window.innerWidth;

  return (
    <Styles.Container
      ref={captureRef}
      className={"satoshi" + (big ? " big" : "") + (big && tall ? " tall" : "")}
    >
      {logo_yn === "Y" && stamp !== 0 && <div className="stamfit">STAMFIT</div>}
      {location.pathname === "/feed"
        ? (
          <div
            className={"img_detail"}
            style={{height: large_yn == "Y" ? w * 1.0974 : w}}>
            <ProgressiveImage
              lowQualitySrc={thumbnail}
              highQualitySrc={image}
              alt={"feedImg"}
              crossOrigin={"Anonymous"}
            />
            {/* <img src={IMAGEPATH + image + "/download"} alt="feedImg" crossOrigin="Anonymous"
            /> */}
          </div>
        ) : (
          <div className={"img"}>
            <img src={IMAGEPATH + thumbnail} alt="feedImg" 
            />
          </div>
        )
      }

      <div className="dateWrap">
        <p className="date">{date.getDateType(created_at?.split(" ")[0], "/")}</p>
        <p className="time">{created_at?.split(" ")[1].slice(0, 5)}</p>
      </div>

      {category_info_id && renderDetail(category_info_id)}
    </Styles.Container>
  );
}

export default FeedList;
