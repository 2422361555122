import styled from "styled-components";

const Container = styled.div`
  margin-bottom: calc(100vw * (10 / 390));
  padding: calc(100vw * (12 / 390));
  background-color: var(--black120);
  border-radius: calc(100vw * (5 / 390));

  .highcharts-subtitle span {
    font-family: "Pretendard Variable";
    font-size: 25px;
    font-weight: 600;
    line-height: 1.4;
  }
`;

export { Container };

export default { Container };
