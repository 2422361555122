import connector from "service/connector";


const getPolicy = async (data) => {
  return await connector.connectFetchController("policy/detail?type=" + data, "GET");
}

const imageProcess = async (data) => {
  return await connector.connectFetchController("upload/image", "POST", data);
}

export {
  getPolicy,
  imageProcess
};

export default {
  getPolicy,
  imageProcess
};
