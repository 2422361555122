import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;

  & .iconArrow {
    width: 12px;
    height: 12px;
  }

  & .paginationList {
    display: flex;
    gap: 30px;
  }

  & .paginationList button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 18px;
    color: var(--gray01);
  }

  & .paginationList button.on {
    font-weight: bold;
    color: var(--black);
  }
`;

export { Container };

export default { Container };
