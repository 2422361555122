import Styles from "./styles";

function BasicCheckbox({ id, checkText, onChange, checked }) {
  return (
    <>
      <Styles.Input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />

      <Styles.Label htmlFor={id}>
        <span className="checkbox"></span>
        {checkText}
      </Styles.Label>
    </>
  );
}

export default BasicCheckbox;
