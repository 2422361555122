import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: 100dvh;

  & header {
    flex-shrink: 0;
    font-size: calc(100vw * (20 / 390));
    font-weight: 600;
    background-color: #fff;
    z-index: 8;

    & .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: calc(100vw * (68 / 390));
    }
  }

  & main {
    & .subscription {
      color: #fff;
      background-color: var(--black130);

      & .inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: calc(100vw * (144 / 390));
      }

      & .premium {
        margin-bottom: calc(100vw * (5 / 390));
        font-size: calc(100vw * (20 / 390));
        font-weight: 600;
      }

      & .text {
        font-weight: 300;

        & span {
          font-weight: 600;
        }
      }

      & .paymentButton {
        width: calc(100vw * (40 / 390));
        height: calc(100vw * (25 / 390));
      }
    }

    & > .inner {
      padding-top: calc(100vw * (20 / 390));
      font-weight: 300;

      & .menuList {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: calc(100vw * (10 / 390)) 0;
        cursor: pointer;

        & .pushButton {
          position: relative;
          width: calc(100vw * (39 / 390));
          height: calc(100vw * (15.6 / 390));
          background-color: var(--black130);
          border-radius: calc(100vw * (130 / 390));

          &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: calc(100vw * (24.7 / 390));
            height: 100%;
            background-color: #fff;
            border: calc(100vw * (1 / 390)) solid var(--black130);
            border-radius: calc(100vw * (130 / 390));
            content: "";
            box-sizing: border-box;
          }

          &.on::after {
            left: auto;
            right: 0;
          }
        }
      }
    }
  }

  & .navigation {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
`;

export { Container };

export default { Container };
